import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Select, MenuItem, TextField, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import { BASE_URL } from '../../../../utils/axiosSetup';
import PageTitle from '../../../common/PageTitle';
import './dashboard.css';

const Dashboard = () => {
  const [issueTypes, setIssueTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const authToken = localStorage.getItem('token');
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
      companyId = undefined
  }
  const filters = {
    compnayId: companyId,
    // status: 'Deferred',
    fromDate,
    toDate,
    status: selectedStatus
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/v1/building/issuetype`, {
          params: filters,
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.code === 200) {
          setIssueTypes(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching issue types:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate, selectedStatus]); 
  return (
    <div>
      <PageTitle title="Dashboard" />

      {/* Filter UI */}
      <div style={{ marginBottom: '20px' }}>
        <FormControl variant="outlined" style={{ marginRight: '20px', width:'250px' }}>
          <InputLabel id="bType-label">Status </InputLabel>
          <Select
            labelId="bType-label"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            label="Issue Type"
            MenuProps={{ disableScrollLock: true }}
          >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Assigned">Assigned</MenuItem>
                <MenuItem value="Deferred">Deferred</MenuItem>
                <MenuItem value="On Hold">On Hold</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Duplicate">Duplicate</MenuItem>
               
          </Select>
        </FormControl>

        {/* Date Pickers */}
        <TextField
          type="date"
          label="From Date"
          variant="outlined"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: '20px' }}
        />
        <TextField
          type="date"
          label="To Date"
          variant="outlined"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div class="fsr-container">
        <div class="fsr-d-flex">
                {loading ? (
                <div>Loading...</div>
                ) : (
                issueTypes.map((issue) => (
                
                            <div class="fsr-dashboard-box col" bis_skin_checked="1">
                                <h5 class=""> {issue.issueType}</h5>
                                <h4 class=""> {issue.count}</h4>
                            </div>          
                
                ))
                )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
