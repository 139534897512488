import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import "./FieldStaff.css"
import PageTitle from '../../common/PageTitle';
import { deleteV2API, getV2API, getV2ExportAPI, patchV2API, postV2API, putV2API } from '../../network/index.v2';
import EmptyTable from '../../common/EmptyTable';
import { tableData } from '../../utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const FieldStaff = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [fieldStaffList, setFieldStaffList] = useState([])
    const [ContractorList, setContractorList] = useState([])
    const enumArray = ['License', 'Police Check', 'Induction', 'Certificate'];
    // const [modalOpen, setModalOpen] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [_id, setId] = useState("")
    const [contractor, setContractor] = useState("")
    const [fieldStaffName, setFieldStaffName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [accreditation1name, setAccreditation1Name] = useState("")
    const [accreditation1issueDate, setAccreditation1IssueDate] = useState("")
    const [accreditation1ExpiryDate, setAccreditation1ExpiryDate] = useState("")
    const [accreditation2name, setAccreditation2Name] = useState("")
    const [accreditation2issueDate, setAccreditation2IssueDate] = useState("")
    const [accreditation2ExpiryDate, setAccreditation2ExpiryDate] = useState("")



    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const [inputs, setInputs] = useState({
        contractor: "",
        fieldStaffName: "",
        phoneNumber: "",
        accreditation1name: "",
        accreditation1issueDate: "2023-01-01",
        accreditation1expiryDate: "2023-01-01",
        accreditation2name: "",
        accreditation2issueDate: "2023-01-01",
        accreditation2expiryDate: "2023-01-01"
    })

    const handleChange = (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };

    const editFieldStaff = (e, _id) => {
        e.preventDefault()

        const filteredId = fieldStaffList?.filter(item => item?._id === _id)
        console.log(filteredId);
        setId(filteredId[0]?._id)
        setFieldStaffName(filteredId[0]?.fieldStaffName)
        setContractor(filteredId[0]?.contractor?._id)
        setAccreditation1ExpiryDate(filteredId[0]?.accreditation1expiryDate.split("T")[0])
        setAccreditation1IssueDate(filteredId[0]?.accreditation1issueDate.split("T")[0])
        setAccreditation1Name(filteredId[0]?.accreditation1name)
        setPhoneNumber(filteredId[0]?.phoneNumber)
        setAccreditation2ExpiryDate(filteredId[0]?.accreditation2expiryDate.split("T")[0])
        setAccreditation2IssueDate(filteredId[0]?.accreditation2issueDate.split("T")[0])
        setAccreditation2Name(filteredId[0]?.accreditation2name)
        setEditModal(true)
        // console.log(accreditation2issueDate, accreditation2ExpiryDate);
        
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        // height: 300,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: "2px"
    };

    const createFieldStaff = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            formData.append("contractor", inputs?.contractor)
            formData.append("fieldStaffName", inputs?.fieldStaffName)
            formData.append("phoneNumber", inputs?.phoneNumber)
            formData.append("accreditation1name", inputs?.accreditation1name)
            formData.append("accreditation1issueDate", inputs?.accreditation1issueDate)
            formData.append("accreditation1expiryDate", inputs?.accreditation1expiryDate)
            formData.append("accreditation2name", inputs?.accreditation2name)
            formData.append("accreditation2issueDate", inputs?.accreditation2issueDate)
            formData.append("accreditation2expiryDate", inputs?.accreditation2expiryDate)

            const response = await postV2API("/maintenance/fieldStaff", formData)
            if (response) {
                getFieldStaff()
                setModalOpen(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getContractorList = async () => {
        try {
            const response = await getV2API("/maintenance/contractor")
            if (response) {
                setContractorList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const getFieldStaff = async () => {
        try {
            const response = await getV2API("/maintenance/fieldStaff")
            if (response) {
                setFieldStaffList(response)
                setModalOpen(false)
                clearInputs()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateFieldStaff = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            formData.append("contractor", contractor)
            formData.append("fieldStaffName", fieldStaffName)
            formData.append("phoneNumber", phoneNumber)
            formData.append("accreditation1name", accreditation1name)
            formData.append("accreditation1issueDate", accreditation1issueDate)
            formData.append("accreditation1expiryDate", accreditation1ExpiryDate)
            formData.append("accreditation2name", accreditation2name)
            formData.append("accreditation2issueDate", accreditation2issueDate)
            formData.append("accreditation2expiryDate", accreditation2ExpiryDate)

            const response = await putV2API(`/maintenance/fieldStaff/${_id}`, formData)
            if (response) {
                getFieldStaff()
                setModalOpen(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFieldStaff = async (id) => {
        try {
            const response = await deleteV2API(`/maintenance/fieldStaff/${id}`)
            if (response) {
                getFieldStaff()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleExport = async (e) => {
        e.preventDefault()

        const response = await getV2ExportAPI("/maintenance/fieldStaff/exports", "FieldStaff")

    }

    const clearInputs = () => {
        setInputs((prev) => ({
            ...prev,
            contractor: "",
            fieldStaffName: "",
            phoneNumber: "",
            accreditation1name: "",
            accreditation1issueDate: "",
            accreditation1expiryDate: "",
            accreditation2name: "",
            accreditation2issueDate: "",
            accreditation2expiryDate: ""
        }))
    }

    console.log(fieldStaffList);

    useEffect(() => {
        getFieldStaff()
        getContractorList()
    }, [])

    return (
        <div>
            <div style={{ width: "100%" }}>
                <Button variant="contained" onClick={() => setModalOpen(true)} style={{ backgroundColor: "grey", float: "right" }} sx={{ height: 30, m: 2 }}>
                    {/* <GetAppRoundedIcon /> &nbsp; &nbsp; */}
                    Add FieldStaff
                </Button>
            </div>
            <div className='export-button' >
                <div style={{ display: "flex", gap: "10px" }}>
                    <p className='titles'>Client</p>
                    <p className='titles'>Supplier</p>
                    <p className='titles'>Field Staff Name</p>
                    <p className='titles'>Status</p>
                    <p className='titles'>Accreditation Name</p>
                </div>
                <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 30, m: 2 }} onClick={handleExport}>
                    <GetAppRoundedIcon /> &nbsp; &nbsp;
                    Export
                </Button>
            </div>
            <div >
                <Box display="flex" sx={{ my: "3rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contract Or Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Email
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Phone
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Field Staff Photo
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contact Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Number
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Issue Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 1 Expiry Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        View Accreditation 1
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Number
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Issue Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Accreditation 2 Expiry Date
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        View Accreditation 2
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Task Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {fieldStaffList?.slice(page * perPage, page * perPage + perPage)?.map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.updatedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.fieldStaffName}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {`${task?.user?.firstname} ${task?.user?.lastname}`}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.phoneNumber}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.fieldStaffPhoto}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.contractor?.contactName}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1issueDate?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1expiryDate?.split("T")[0]}
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell> */}
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation2name}
                                        </TableCell>

                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1issueDate?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1issueDate?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1issueDate?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1expiryDate?.split("T")[0]}

                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.accreditation1expiryDate?.split("T")[0]}

                                        </TableCell>

                                        <TableCell align="left" sx={{ display: "flex" }} >
                                            <Button size="small" className="btn-div" variant="outlined" color="info"
                                                onClick={(e) => editFieldStaff(e, task?._id)}
                                            >
                                                <EditIcon className="" />
                                            </Button>
                                            <Button size="small" className="btn-div" variant="outlined" color="error"
                                                onClick={() => deleteFieldStaff(task?._id)}
                                            >
                                                <DeleteIcon className="" />
                                            </Button>
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell> */}

                                    </TableRow>

                                ))}

                                {
                                    fieldStaffList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={fieldStaffList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </div>

            <div>
                <Modal
                    open={modalOpen}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <Grid className='box-title'>
                            <PageTitle title={"Add Field Staff"} />
                        </Grid>
                        <form onSubmit={createFieldStaff}>
                            <Grid style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Contractor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={inputs?}
                                        name='contractor'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            ContractorList?.map((contractor) => {
                                                return <MenuItem key={contractor?._id} value={contractor?._id}>{contractor?.supplierName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Field Staff Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={inputs?}
                                        name='contractor'
                                        label="Field Staff Name"
                                        onChange={handleChange}
                                    >
                                        {
                                            enumArray?.map((item, i) => {
                                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl> */}
                                <TextField variant='outlined' label="Field Staff Name" className='box-input' onChange={handleChange} name='fieldStaffName' value={inputs?.fieldStaffName} />
                                <TextField variant='outlined' label="Field Staff Phone Number" className='box-input' onChange={handleChange} name='phoneNumber' value={inputs?.phoneNumber} />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Accreditation 1 Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.accreditation1name}
                                        name='accreditation1name'
                                        label="Accreditation 1 Name"
                                        onChange={handleChange}
                                    >
                                        {
                                            enumArray?.map((item, i) => {
                                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 1 Issue Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={inputs.accreditation1issueDate}
                                            error={inputs.accreditation1issueDate}
                                            onChange={(data) => {
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    accreditation1issueDate: data,
                                                }))
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                {/* <TextField variant='outlined' label="Accreditation 1 Name" className='box-input' onChange={handleChange} name='accreditation1name' value={inputs?.accreditation1name} /> */}
                                {/* <TextField variant='outlined' label="Accreditation 1 Issue Date" className='box-input' onChange={handleChange} name='accreditation1issueDate' value={inputs?.accreditation1issueDate} /> */}
                                {/* <TextField variant='outlined' label="Accreditation 1 Expiry Date" className='box-input' onChange={handleChange} name='accreditation1expiryDate' value={inputs?.accreditation1expiryDate} /> */}
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 1 Expiry Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={inputs.accreditation1expiryDate}
                                            // error={inputs.accreditation1expiryDate}
                                            onChange={(data) => {
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    accreditation1expiryDate: data,
                                                }))
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Accreditation 2 Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.accreditation2name}
                                        name='accreditation2name'
                                        label="Accreditation 2 Name"
                                        onChange={handleChange}
                                    >
                                        {
                                            enumArray?.map((item, i) => {
                                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 2 Expiry Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={inputs.accreditation2expiryDate}
                                            // error={inputs.accreditation2expiryDate}
                                            onChange={(data) => {
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    accreditation2expiryDate: data,
                                                }))
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 2 Issue Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={inputs.accreditation2issueDate}

                                            // error={inputs.accreditation2issueDate}
                                            onChange={(data) => {
                                                setInputs(prevState => ({
                                                    ...prevState,
                                                    accreditation2issueDate: data,
                                                }))
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                {/* <TextField variant='outlined' label="Accreditation 2 Name" className='box-input' onChange={handleChange} name='accreditation2name' value={inputs?.accreditation2name} /> */}
                                {/* <TextField variant='outlined' label="Accreditation 2 Expiry Date" className='box-input' onChange={handleChange} name='accreditation2issueDate' value={inputs?.accreditation2issueDate} /> */}

                                {/* <TextField variant='outlined' label="Accreditation 1 Issue Date" className='box-input' onChange={handleChange} name='accreditation2expiryDate' value={inputs?.accreditation2expiryDate} /> */}
                                {/* <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' /> */}
                            </Grid>
                            <Grid className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
                            </Grid>
                        </form>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={editModal}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <Grid className='box-title'>
                            <PageTitle title={"Add Field Staff"} />
                        </Grid>
                        <form onSubmit={updateFieldStaff}>
                            <Grid style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Contractor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={contractor}
                                        name='contractor'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            ContractorList?.map((contractor) => {
                                                return <MenuItem key={contractor?._id} value={contractor?._id}>{contractor?.supplierName}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <TextField variant='outlined' label="Field Staff Name" className='box-input' onChange={(e)=>setFieldStaffName(e.target.value)} name='fieldStaffName' value={fieldStaffName} />
                                <TextField variant='outlined' label="Field Staff Phone Number" className='box-input' onChange={(e) =>setPhoneNumber(e.target.value)} name='phoneNumber' value={phoneNumber} />
                                {/* <TextField variant='outlined' label="Accreditation 1 Name" className='box-input' onChange={handleChange} name='accreditation1name' value={accreditation1name} />
                                <TextField variant='outlined' label="Accreditation 1 Issue Date" className='box-input' onChange={handleChange} name='accreditation1issueDate' value={accreditation1issueDate} />
                                <TextField variant='outlined' label="Accreditation 1 Expiry Date" className='box-input' onChange={handleChange} name='accreditation1expiryDate' value={accreditation1ExpiryDate} />
                                <TextField variant='outlined' label="Accreditation 2 Name" className='box-input' onChange={handleChange} name='accreditation2name' value={accreditation2name} />
                                <TextField variant='outlined' label="Accreditation 2 Expiry Date" className='box-input' onChange={handleChange} name='accreditation2issueDate' value={accreditation2issueDate} />
                                <TextField variant='outlined' label="Accreditation 1 Issue Date" className='box-input' onChange={handleChange} name='accreditation2expiryDate' value={accreditation2ExpiryDate} /> */}
                                {/* <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' />
              <TextField variant='outlined' label="Property Name" className='box-input' /> */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Accreditation 1 Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={accreditation1name}
                                        name='accreditation1name'
                                        label="Accreditation 1 Name"
                                        onChange={(e)=>setAccreditation1Name(e.target.value)}
                                    >
                                        {
                                            enumArray?.map((item, i) => {
                                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 1 Issue Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={accreditation1issueDate}
                                            error={accreditation1issueDate}
                                            onChange={(data) => setAccreditation1IssueDate(data)}
                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                {/* <TextField variant='outlined' label="Accreditation 1 Name" className='box-input' onChange={handleChange} name='accreditation1name' value={inputs?.accreditation1name} /> */}
                                {/* <TextField variant='outlined' label="Accreditation 1 Issue Date" className='box-input' onChange={handleChange} name='accreditation1issueDate' value={inputs?.accreditation1issueDate} /> */}
                                {/* <TextField variant='outlined' label="Accreditation 1 Expiry Date" className='box-input' onChange={handleChange} name='accreditation1expiryDate' value={inputs?.accreditation1expiryDate} /> */}
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 1 Expiry Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={accreditation1ExpiryDate}
                                            onChange={(data) => setAccreditation1ExpiryDate(data)}

                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Accreditation 2 Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={accreditation2name}
                                        name='accreditation2name'
                                        label="Accreditation 2 Name"
                                        onChange={(e) => setAccreditation2Name(e.target.value)}

                                    >
                                        {
                                            enumArray?.map((item, i) => {
                                                return <MenuItem key={i} value={item}>{item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 2 Expiry Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={accreditation2ExpiryDate}
                                            onChange={(data) => setAccreditation2ExpiryDate(data)}

                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                <FormControl style={{ width: "100%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            className="modal-component"
                                            label="Accreditation 2 Issue Date"
                                            style={{ width: "100%" }}
                                            inputFormat="dd/MM/yyyy"
                                            value={accreditation2issueDate}

                                            // error={inputs.accreditation2issueDate}
                                            onChange={(data) => setAccreditation2IssueDate(data)}

                                            renderInput={(params) => <TextField {...params} />}
                                            // error={startDateError}
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setEditModal(false)} >Cancel</Button>
                            </Grid>
                        </form>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default FieldStaff