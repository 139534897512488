import React, { useEffect, useState } from 'react'
import PageTitle from '../../common/PageTitle'
import { checkAuthority } from '../../utils';
import SiteDetailCard from '../../components/sites/DetailCard';
import "./Fsr.css"

const Fsr = () => {
    const [fsrList, setFsrList] = useState([])
    const segments = window.location?.pathname;

    useEffect(() => {
        getManagementDetails()
    }, [segments])

    const getManagementDetails = () => {
        var arr = []
        let url = segments?.split("/")[1] === 'company' ? '/company' : ''

        if (checkAuthority('VIEW_FACILITY_SERVICE_REQUESTS')) {
            arr.push({
                id: 1,
                title: "Facility Service Requests",
                url: `${url}/fsr/facilities`
            })
        }

        if (checkAuthority('VIEW_FACILITY_SETTINGS')) {
            arr.push({
                id: 2,
                title: "Facility Settings",
                url: `${url}/fsr/settings`
            })
        }

        if (checkAuthority('VIEW_FACILITY_SERVICE_REQUESTS')) {
            arr.push({
                id: 3,
                title: "Dashboard",
                url: `${url}/fsr/dashboard`
            })
        }

        setFsrList(arr)
    }
    return (
        <div>
            <PageTitle title={"FSR"} />

            <div className='datacard' >
                {fsrList?.map((fsr) => {
                    return (
                        <SiteDetailCard title={fsr?.title} url={fsr?.url} />
                    )
                })}
            </div>
        </div>
    )
}

export default Fsr