import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { getV2API, getV2ExportAPI } from '../../../network/index.v2';
import { tableData } from '../../../utils';
import EmptyTable from '../../../common/EmptyTable';

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const ViewAdminContract = () => {
    const [adminContractorList, setAdminContractorList] = useState([])

    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

    const getContractorList = async () => {
        try {
            const response = await getV2API("/maintenance/contractor/admin")
            if (response) {
                setAdminContractorList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const handleExport = async(e) => {
        e.preventDefault()

        const response = await getV2ExportAPI("/maintenance/contractor/admin/exports", "Admin_Contractor")

    }

    useEffect(() => {
        getContractorList()
    }, [])
    return (
        <div>

            <div>

                <div className='export-button' style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "end" }}>
                <Button variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }} onClick={handleExport}>
                    {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                   Export
                </Button>
                </div>

                <Box display="flex" sx={{ my: "1rem" }}>
                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Number
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Admin Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        AdminEmail
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Admin Phone
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                        Task Status
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                        Action
                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {adminContractorList?.slice(page * perPage, page * perPage + perPage)?.map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.contractorId?.phoneNumber}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.siteId?.name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminName}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminEmail}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.adminPhoneNumber}
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                            {task?.checkIn?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>

                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                        </TableCell> */}

                                    </TableRow>

                                ))}

                                {
                                    adminContractorList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                    
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={adminContractorList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default ViewAdminContract