import React, { useEffect, useState } from "react";
import { Box, FormControl, Button } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Loader from "../../common/Loader";
import Select from 'react-select'
// import AsyncSelect from 'react-select/async';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableFooter from "@mui/material/TableFooter";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
// import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Grid from '@mui/material/Grid';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';

import './style.css'
import { deleteAPI, getAPI, patchAPI, postAPI } from "../../network";
// import { setISODay } from "date-fns";
import { validation, tableHeader, tableData } from "../../utils";
import EmptyTable from "../../common/EmptyTable";
import { Autocomplete, Grid, MenuItem } from "@material-ui/core";
import DropdownWithSearch from "../../CustomComponent/SearchSelect";
// import { MenuItem } from "@material-ui/core";

export default function Role() {
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState('add')
  const [id, setId] = useState(0)
  // const [newPermission, setNewPermission] = useState('')
  // const [addPermission, setAddPermission] = useState(false)
  // const [newPermissionError, setNewPermissionError] = useState(false)

  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }

  const [role, setRole] = useState({
    id: '',
    idError: false,
    name: '',
    nameError: false,
    menuPermissions: [],
    submenuPermissions: [],
    actionPermissions: [],
    subactionPermissions: [],
    companyId: companyId,
    permissionsError: false
  })

  const [menuPermissions, setMenuPermissions] = useState([])
  const [submenuPermissions, setSubmenuPermissions] = useState([])
  const [actionPermissions, setActionPermissions] = useState([])
  const [subactionPermissions, setSubActionPermissions] = useState([])

  const [roles, setRoles] = useState([])
  const [rolesDropdown, setRolesDropdown] = useState([])
  const [rolesDropdownValue, setRolesDropdownValue] = useState()

  useEffect(() => {
    getRoles()
    getRoles2()
    getMenuPermissions()
    // getSubmenuPermissions()
  }, []);

  const getRoles = async (filter) => {
    setLoader(true)
    let data;
    if (filter) {
      data = await getAPI(`/adm/roles?companyId=${companyId}&id=${filter}`);
    } else {
      data = await getAPI(`/adm/roles?companyId=${companyId}`);
    }
    if (data) {
      setRoles(data)
    }
    setLoader(false)
  }
  const getRoles2 = async (filter) => {
    setLoader(true)
    let data = await getAPI(`/adm/roles?companyId=${companyId}`);
    if (data) {
      setRoles(data)
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push({ id: data[i]._id, label: data[i].name, value: data[i]._id });
      }
      setRolesDropdown(arr)
    }
    setLoader(false)
  }

  const getMenuPermissions = async () => {
    setLoader(true)
    let data = await getAPI('/adm/modules/menu');
    if (data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push({ id: data[i]._id, label: data[i].menu, value: data[i]._id });
      }
      setMenuPermissions(arr)
    }
    setLoader(false)
  }
  const getSubmenuPermissions = async (menus) => {
    setLoader(true)
    const idsArray = menus?.map(item => item?.id);
    let data = await getAPI(`/adm/modules/submenu?menus=${idsArray}`);
    if (data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push({ id: data[i]._id, label: `${data[i].submenu}( ${data[i]?.menu?.menu} )`, value: data[i]._id });
      }
      setSubmenuPermissions(arr)
    }
    setLoader(false)
  }
  const getActionPermissions = async (submenus) => {
    setLoader(true)
    const idsArray = submenus?.map(item => item?.id);

    let data = await getAPI(`/adm/modules/action?submenus=${idsArray}`);
    if (data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push({ id: data[i]._id, label: `${data[i].action}( ${data[i]?.submenu?.submenu} )`, value: data[i]._id });
      }
      setActionPermissions(arr)
    }
    setLoader(false)
  }
  const getSubActionPermissions = async (submenus) => {
    setLoader(true)
    const idsArray = submenus?.map(item => item?.id);

    let data = await getAPI(`/adm/modules/subaction?actions=${idsArray}`);
    if (data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        arr.push({ id: data[i]._id, label: `${data[i].subAction}( ${data[i]?.action?.action} )( ${data[i]?.submenu?.submenu} )`, value: data[i]._id });
      }
      setSubActionPermissions(arr)
    }
    setLoader(false)
  }


  const addRole = () => {
    setRole({
      id: '',
      idError: false,
      name: '',
      permissions: [],
      nameError: false,
      permissionsError: false,
    })
    setAction('add')
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (type) => {
    setRole({
      ...role,
      nameError: false,
      permissionsError: false
    })

    if (validation(null, 'Role Name', role.name)) {
      setRole({
        ...role,
        nameError: true,
      })
      return false;
    }
    else if (validation('array', 'Menu Permissions', role.menuPermissions)) {
      setRole({
        ...role,
        permissionsError: true,
      })
      return false;
    }

    let menuPermissions = [];
    let submenuPermissions = [];
    let actionPermissions = [];
    let subactionPermissions = [];
    console.log(role)
    for (let i = 0; i < role.menuPermissions.length; i++) {
      menuPermissions.push(role.menuPermissions[i].id);
    }
    if (role?.submenuPermissions?.length) {
      for (let i = 0; i < role.submenuPermissions.length; i++) {
        submenuPermissions.push(role.submenuPermissions[i].id);
      }
    }

    if (role?.actionPermissions?.length) {
      for (let i = 0; i < role.actionPermissions.length; i++) {
        actionPermissions.push(role.actionPermissions[i].id);
      }
    }

    if (role?.subactionPermissions?.length) {
      for (let i = 0; i < role?.subactionPermissions.length; i++) {
        subactionPermissions.push(role.subactionPermissions[i].id);
      }
    }

    let payload = {
      name: role.name,
      menuPermissions: menuPermissions,
      submenuPermissions: submenuPermissions,
      actionPermissions: actionPermissions,
      subactionPermissions: subactionPermissions,
      companyId: companyId
    }

    setLoader(true)

    var data = false;
    if (action === 'add') {
      data = await postAPI('/adm/roles', payload)
    }
    else {
      // console.log(`/adm/roles/${role.id}`)
      // console.log(payload)
      data = await patchAPI(`/adm/roles/${role.id}`, payload)
    }
    if (data) {
      getRoles()
      getMenuPermissions();
      setOpen(false)
    }

    setLoader(false)


  }

  const editRoleClick = (id) => {
    console.log(id)
    let data = roles.filter((item) => item._id === id)[0]
    console.log(data)
    let mp = [];
    let smp = [];
    let ap = [];
    let sap = [];
    if (data) {
      for (let i = 0; i < data?.menuPermissions?.length; i++) {
        mp.push({ id: data.menuPermissions[i]?._id, label: data.menuPermissions[i]?.menu, value: data.menuPermissions[i]?._id });
      }
      for (let i = 0; i < data?.submenuPermissions?.length; i++) {
        smp.push({ id: data.submenuPermissions[i]?._id, label: data.submenuPermissions[i]?.submenu, value: data.submenuPermissions[i]?._id });
      }
      for (let i = 0; i < data?.actionPermissions?.length; i++) {
        ap.push({ id: data.actionPermissions[i]?._id, label: data.actionPermissions[i]?.action, value: data.actionPermissions[i]?._id });
      }
      for (let i = 0; i < data?.subactionPermissions?.length; i++) {
        sap.push({ id: data.subactionPermissions[i]?._id, label: data.subactionPermissions[i]?.subAction, value: data.subactionPermissions[i]?._id });
      }
    }

    setRole({
      id: id,
      idError: false,
      name: data?.name,
      nameError: false,
      menuPermissions: mp,
      submenuPermissions: smp,
      actionPermissions: ap,
      subactionPermissions: sap,
      companyId: companyId,
      permissionsError: false
    });


    setAction('edit')

    setOpen(true)
  }

  const handleShowClose = () => {
    setShow(false)
  }

  // ? [...prevRole.menuPermissions, ...data]               // Append the new data

  const changeMenuPermission = (data) => {
    console.log(data, "data");

    setRole((prevRole) => ({
      ...prevRole,
      menuPermissions: Array.isArray(prevRole.menuPermissions) // Ensure it's an array
        ? [...data]               // Append the new data
        : [...data],                                           // If not an array, initialize 
    }));
    getSubmenuPermissions(data)
  };

  console.log(role);


  const changeSubmenuPermission = (data) => {
    setRole((prevRole) => ({
      ...prevRole,
      submenuPermissions: Array.isArray(prevRole.submenuPermissions) // Ensure it's an array
        ? [...data]               // Append the new data
        : [...data],                                           // If not an array, initialize 
    }));
    getActionPermissions(data)
  };
  const changeActionPermission = (data) => {
    setRole((prevRole) => ({
      ...prevRole,
      actionPermissions: Array.isArray(prevRole.actionPermissions) // Ensure it's an array
        ? [...data]               // Append the new data
        : [...data],                                           // If not an array, initialize 
    }));
    getSubActionPermissions(data)
  };
  const changeSubActionPermission = (data) => {
    setRole((prevRole) => ({
      ...prevRole,
      subactionPermissions: Array.isArray(prevRole.subactionPermissions) // Ensure it's an array
        ? [...data]               // Append the new data
        : [...data],                                           // If not an array, initialize 
    }));
  };

  const deleteRole = async (id) => {
    setLoader(true)
    let data = await deleteAPI(`/adm/roles/${id}`);
    if (data) {
      getRoles()
      setShow(false)
    }
    setLoader(false)
  }

  console.log(menuPermissions, "menuPermissions");
  console.log(submenuPermissions, "submenuPermissions");
  console.log(actionPermissions, "actionPermissions");
  console.log(subactionPermissions, "subactionPermissions");


  return (
    <Box sx={{ height: "inherit" }}>
      <Loader loader={loader} />
      <PageTitle title="Admin Role" />
      <Box
        display="flex"
        justifyContent="between"
        alignItems="center"
        // flexDirection={"column"}
        // sx={{ mx: 2 }}
        width={"100%"}
      >
        {/* <h1>     {rolesDropdownValue ? null : "Please Select Role From dropdown"}</h1> */}

        <FormControl sx={{ minWidth: '80%', px: 0 }}>
          <TextField
            select
            label="Role Filter"
            value={rolesDropdownValue}
            style={{ width: "30%" }}
            onChange={(data) => {
              setRolesDropdownValue(data.target.value)
              getRoles(data.target.value)
            }}
            variant="outlined"
            fullWidth
            sx={{ minWidth: '25%', mx: 2, px: 0 }}
          >
            {rolesDropdown.map((site) => (
              <MenuItem key={site.id} value={site.value}>
                {site.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <Button variant="contained" size="medium" style={{ backgroundColor: "#75859D", float: "right" }} onClick={addRole}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Role
        </Button>

        {/* <Button variant="contained" size="medium" style={{ backgroundColor: "#75859D" }} sx={{ ml: 2 }} onClick={addPermissionClick}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Permission
        </Button> */}
      </Box>

      <Box display="flex" sx={{ my: "2rem" }}>

        <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
          <Table
            // sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header" align="center">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Role
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Menu Permissions
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Submenu Permissions
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action Permissions
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Subaction Permissions
                </TableCell>
                <TableCell align="center" component="th" sx={tableHeader} >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((item, index) => (
                <TableRow key={index}>

                  <TableCell align="left" sx={tableData}>
                    {item?.name}
                  </TableCell>

                  <TableCell align="left" sx={tableData} className="permission">
                    {item?.menuPermissions?.map((mp, index) => (
                      <li key={index}>{mp?.menu}</li>
                    ))}
                  </TableCell>

                  <TableCell align="left" sx={tableData} className="permission">
                    {item?.submenuPermissions?.map((smp, index) => (
                      <li key={index}>{smp?.submenu}</li>
                    ))}
                  </TableCell>

                  <TableCell align="left" sx={tableData} className="permission">
                    {item?.actionPermissions?.map((ap, index) => (
                      <li key={index}>{ap?.action}</li>
                    ))}
                  </TableCell>
                  <TableCell align="left" sx={tableData} className="permission">
                    {item?.subactionPermissions?.map((sap, index) => (
                      <li key={index}>{sap?.subAction}</li>
                    ))}
                  </TableCell>

                  <TableCell align="center" sx={{ textAlign: 'center', tableData }}>
                    <Button variant="outlined" color="primary" className="btn-div" onClick={() => editRoleClick(item?._id)} sx={{ mr: 2 }}>
                      <EditIcon className="" />
                    </Button>
                    <Button variant="outlined" color="error" className="btn-div" onClick={() => deleteRole(item?._id)} sx={{ mr: 2 }}>
                      <DeleteIcon className="" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {
                roles.length === 0 &&
                <EmptyTable colSpan={3} />
              }

            </TableBody>

          </Table>
          {/* </div> */}
        </TableContainer>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>
          {action === 'add' ? 'Add' : 'Edit'} Role
        </DialogTitle>
        <DialogContent sx={{ height: "auto" }}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },

            }}
            noValidate
            autoComplete="off"
          >



            <FormControl sx={{ minWidth: '100%', mx: 0, px: 0 }}>
              <TextField
                id="name"
                label="Role Name"
                variant="outlined"
                type="text"
                value={role.name}
                error={role.nameError}
                onChange={(data) => {
                  setRole(prevState => ({
                    ...prevState,
                    name: data.target.value,
                  }))
                }}
                fullWidth
              // sx={{ m: 0 }}
              />
            </FormControl>

            <Grid sx={{
              display: "grid",
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: "10px"
            }}>

              <Autocomplete
                multiple
                options={menuPermissions} // Use menuPermissions array here
                getOptionLabel={(option) => option.label} // Display the label from each object
                onChange={(event, newValue) => changeMenuPermission(newValue)} // Update the state when value changes
                value={role.menuPermissions} // Current selected values
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Menu Permissions"
                    variant="outlined"
                    error={role.permissionsError}
                  />
                )}
                sx={{ '& .MuiAutocomplete-listbox': { zIndex: 9999 } }} // Custom styling for dropdown
              />


              <Autocomplete
                multiple
                options={submenuPermissions} // Use menuPermissions array here
                getOptionLabel={(option) => option.label} // Display the label from each object
                onChange={(event, newValue) => changeSubmenuPermission(newValue)} // Update the state when value changes
                value={role.submenuPermissions} // Current selected values
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sub Menu Permissions"
                    variant="outlined"
                    error={role.permissionsError}
                  />
                )}
                sx={{ '& .MuiAutocomplete-listbox': { zIndex: 9999 } }} // Custom styling for dropdown
              />

              <Autocomplete
                multiple
                options={actionPermissions} // Use menuPermissions array here
                getOptionLabel={(option) => option.label} // Display the label from each object
                onChange={(event, newValue) => changeActionPermission(newValue)} // Update the state when value changes
                value={role.actionPermissions} // Current selected values
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Action Permissions"
                    variant="outlined"
                    error={role.permissionsError}
                  />
                )}
                sx={{ '& .MuiAutocomplete-listbox': { zIndex: 9999 } }} // Custom styling for dropdown
              />
              <Autocomplete
                multiple
                options={subactionPermissions} // Use menuPermissions array here
                getOptionLabel={(option) => option.label} // Display the label from each object
                onChange={(event, newValue) => changeSubActionPermission(newValue)} // Update the state when value changes
                value={role.subactionPermissions} // Current selected values
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Action Permissions"
                    variant="outlined"
                    error={role.permissionsError}
                  />
                )}
                sx={{ '& .MuiAutocomplete-listbox': { zIndex: 9999 } }} // Custom styling for dropdown
              />


              {/* <FormControl sx={{ minWidth: '97%', mx: 0, px: 0, mb: 10 }}>

                <Select
                  isMulti
                  name="menuPermissions"
                  options={menuPermissions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => { changeMenuPermission(data) }}
                  error={role.permissionsError}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </FormControl>
              <FormControl sx={{ minWidth: '97%', mx: 0, px: 0, mb: 10 }}>

                <Select
                  isMulti
                  name="permissions"
                  options={submenuPermissions}

                  className="basic-multi-select "
                  classNamePrefix="select"
                  onChange={(data) => { changeSubmenuPermission(data) }}
                  error={role.permissionsError}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 20,
                    }),
                  }}
                />
              </FormControl>
              <FormControl sx={{ minWidth: '97%', mx: 0, px: 0, mb: 10 }}>

                <Select
                  isMulti
                  name="permissions"
                  options={actionPermissions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => { changeActionPermission(data) }}
                  error={role.permissionsError}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                />
              </FormControl>
              <FormControl sx={{ minWidth: '97%', mx: 0, px: 0, mb: 10 }}>

                <Select
                  isMulti
                  name="permissions"
                  options={subactionPermissions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => { changeSubActionPermission(data) }}
                  error={role.permissionsError}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                />
              </FormControl> */}
            </Grid>



          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleSubmit} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* 

      <Dialog open={addPermission} onClose={handlePermissionClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>
          Add Permission
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <FormControl sx={{ minWidth: '97%', mx: 0, px: 0 }}>
              <TextField
                id="permission_name"
                label="Permission Name"
                variant="outlined"
                type="text"
                value={newPermission}
                error={newPermissionError}
                onChange={(data) => {
                  setNewPermission((data.target.value.toUpperCase()))
                }}
                fullWidth
                sx={{ m: 0 }}
              />
            </FormControl>

          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={() => { handlePermissionSubmit(action) }} variant="contained">{action === 'add' ? 'Submit' : 'Update'}</Button>
          <Button onClick={handlePermissionClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog> */}


      {/* delete Modal */}
      <Dialog open={show} onClose={handleShowClose} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Role</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold', textAlign: 'center' }}>Do you want's to delete this ROLE </h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={deleteRole} variant="contained" color="error">Delete</Button>
          <Button onClick={handleShowClose} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
