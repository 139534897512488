import React, { useEffect, useState, useContext } from "react";
import { Box, MenuItem, FormControl, Select, Button } from "@mui/material";
import Table from "@mui/material/Table";
import InputLabel from '@mui/material/InputLabel';
import TableFooter from "@mui/material/TableFooter";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import EditIcon from '@mui/icons-material/Edit';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from "../../common/PageTitle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { getAPI, patchAPI, postAPI, deleteAPI, getExportAPI } from "../../network";
import { formatDate, formatDatePost, fullName, tableHeader, tableData } from "../../utils";
import EmptyTable from "../../common/EmptyTable";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { BASE_URL } from "../../../utils/axiosSetup";
import { useParams } from 'react-router-dom';



export default function ViewAdvertisedShiftsBreakdownPage() {
  const { groupId } = useParams();
  const [schedulers, setSchedulers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState([]);
  const [open, setOpen] = useState();
  const [show, setShow] = useState(false);
  const [assignuser, setAssignUser] = useState('');
  const [site, setSite] = useState("");
  const [editdelId, setDelEditId] = useState('');
  const [del, setDel] = useState(false);
  const [interestedUser, setInterestedUser] = useState(false);
  const [interestedUserList, setInterestedUserList] = useState([]);
  const [sites, setSites] = useState([])
  const [editId, setEditId] = useState('');
  const [assignId, setAssignId] = useState('')
  const [assignUserId, setAssignUserId] = useState('')
  const [page, setPage] = useState(0);
  const navigateTo = useNavigate();
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const location = useLocation();
  const { details } = location.state || {};
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  useEffect(() => {
    getShedulers()
    getKeyShift()
  }, []);

  const getShedulers = async (statusid = null) => {
    var newStatus = statusid != null ? statusid : selectedStatus.shiftStatus
    var url = (newStatus != null && newStatus != "") ? `&status=${newStatus}` : ""
    setLoader(true)
    if (newStatus) {
      let data = await getAPI(`/company/shifts/group-by-id/${groupId}?status=${newStatus}`)
      if (data) {
        setSchedulers(data)
      }
    }
    else {
      let data = await getAPI(`/company/shifts/group-by-id/${groupId}${url}`)
      if (data) {
        setSchedulers(data)
      }
    }

    setLoader(false)
  }






  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const getKeyShift = async () => {
    setLoader(true)
    let data = await getAPI(`/key-shift-duties?shiftId=${details?._id}`)
    if (data) {
      setKey(data[0].key)
    }
    setLoader(false)
  }


  const [selectedStatus, setSelectedStatus] = useState({});



  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Casual Shifts" subTitle="Breakdown" />
      <Box>
        <Box ml={1} sx={{ display: "flex" }}>
          <TableContainer component={Paper} style={{ maxWidth: 600, margin: "20px auto", padding: "20px" }}>
            <Table aria-label="custom table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Key Shift Duties
                  </TableCell>
                </TableRow>
                <TableCell>{key}</TableCell>
              </TableBody>
            </Table>

          </TableContainer>

          <TableContainer component={Paper} style={{ maxWidth: 600, margin: "20px auto", padding: "20px" }}>
            <Table aria-label="custom table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Site
                  </TableCell>
                  <TableCell>{details?.siteId?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Vendor
                  </TableCell>
                  <TableCell>{details?.vendorId?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    WO. Number
                  </TableCell>
                  <TableCell>{details?.woNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Gross Hourly Rate
                  </TableCell>
                  <TableCell>{details?.price}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Allowed Break
                  </TableCell>
                  <TableCell>{details?.allowedBreaks}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Parking
                  </TableCell>
                  <TableCell>{details?.parkingRequired}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    License Type
                  </TableCell>
                  <TableCell>{details?.licenseType?.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    License Name
                  </TableCell>
                  <TableCell>{details?.licenseType?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Shift Type
                  </TableCell>
                  <TableCell>{details?.shiftType?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                    Shift Name
                  </TableCell>
                  <TableCell>{details?.shiftName}</TableCell>
                </TableRow>
                {/* <TableRow>
                <TableCell component="th" scope="row" style={{ fontWeight: "bold" }}>
                  Key Shift Duties
                </TableCell>
                <TableCell>{details?.keyShiftDuties}</TableCell>
              </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Box my={5} />
          <TableContainer component={Paper} sx={{ mx: "0.8rem" }}>
            <Table
              sx={{ minWidth: "auto" }}
              aria-label="custom pagination table"
              className="responsive-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell
                    align="left"
                    component="th"
                    sx={tableHeader}
                    style={{ width: "13%" }}
                  >
                    Shift Date
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Day
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Start Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    End Time
                  </TableCell>
                  <TableCell align="center" component="th" sx={tableHeader}>
                    Status
                  </TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {schedulers.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="left" sx={tableData}>
                      {formatDate(item?.shiftDate)}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {/* {days[item?.shiftDate.getDay()]} */}
                      {days[new Date(item?.shiftDate).getDay()]}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item?.startTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item?.endTime}
                    </TableCell>
                    <TableCell align="center" sx={tableData}>
                      {item?.status}
                    </TableCell>
                  </TableRow>
                ))}

                {
                  schedulers.length === 0 &&
                  <EmptyTable colSpan={9} />
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={perPages}
                    colSpan={9}
                    count={schedulers.length}
                    rowsPerPage={perPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>

      </Box>

    </Box>
  );
}
