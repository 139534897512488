import axios from "axios";
// import {BASE_URL} from "../constant";

// export const BASE_URL = "http://54.90.21.249:8000/v1";
// export const BASE_URL = "http://13.58.38.66:8000/v1";

// export const BASE_URL = "http://localhost:8000/v1";
export const BASE_URL = "https://secuber.myappsdevelopment.co.in/v1";
export const HOST_URL = "https://adminsecuber.myappsdevelopment.co.in/"

// export const BASE_URL = "http://localhost:8000/v1";
// export const BASE_URL = "http://secuber.myappsdevelopment.co.in/v1";

const token = localStorage.getItem("token");

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: !!token ? { Authorization: `Bearer ${token}` } : null,
});
axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            const status = error.response.status;
            const contentType = error.response.headers["content-type"];
            const isHtmlError = contentType?.includes("text/html") && typeof error.response.data === "string";
            const isJsonError = contentType?.includes("application/json");

            if (isHtmlError) {
                const htmlContent = encodeURIComponent(error.response.data);
                window.location.href = `/html-error?content=${htmlContent}`;
            } else if (isJsonError) {
                const jsonError = JSON.stringify(error.response.data, null, 2);
                window.location.href = `/html-error?content=${encodeURIComponent(jsonError)}`;
            }

            if (status === 401) {
                localStorage.removeItem("token");
                localStorage.clear();
                window.location.href = "/login";
            } else if (status === 403) {
                window.location.href = "/permission";
                localStorage.clear();
            }
        }
        return Promise.reject(error);
    }
);
