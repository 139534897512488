import React, { useEffect, useState } from 'react'
import PageTitle from '../../../../common/PageTitle'
import { Box, Button, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { tableHeader } from '../Addproperty';
import { tableData } from '../../../../utils';
import { deleteV2API, getV2API, postV2API, putV2API } from '../../../../network/index.v2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EmptyTable from '../../../../common/EmptyTable';



const Property = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const [propertyList, setPropertyList] = useState([])
  const [PropertyCategoryList, setPropertyCategoryList] = useState([])
  const [propertyTypeList, setPropertyTypeList] = useState([])
  const [inputs, setInputs] = useState({
    name: "",
    propertytypeId: "",
    propertycategoryId: "",
    propertyId: '',
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    suburb: "",
    latLong: "37.7749,-122.4194"
  })
  const [title, setTitle] = useState("")
  const [propertyTypeId, setPropertyTypeId] = useState("")
  const [_id, setId] = useState("")
  const [propertyCategoryId, setPropertyCategoryId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [state, setState] = useState("")
  const [suburb, setSuburb] = useState("")
  const [latLong, setLatLong] = useState("")
  const [propertyId, setPropertyId] = useState("")
  const [country, setCountry] = useState("")


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height: 300,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderRadius: "2px"
  };

  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  const handleChange = (e) => {
    e.preventDefault()

    setInputs({ ...inputs, [e.target.name]: e.target.value })

  }

  const editProperty = (e, _id) => {
    e.preventDefault()

    const filteredId = propertyList?.filter(item => item?._id === _id)
    setId(filteredId[0]?._id)
    setTitle(filteredId[0]?.name)
    setPropertyTypeId(filteredId[0]?.propertytypeId?._id)
    setPropertyCategoryId(filteredId[0]?.propertycategoryId?._id)
    setPropertyId(filteredId[0]?.propertyId)
    setAddress(filteredId[0]?.address)
    setPhoneNumber(filteredId[0]?.phoneNumber)
    setState(filteredId[0]?.state)
    setCountry(filteredId[0]?.country)
    setSuburb(filteredId[0]?.suburb)
    setLatLong(filteredId[0]?.latLong)
    setEditModal(true)

  }

  // -------------------------------------------- Property  CRUD starts -----------------------------------------------------------

  const createProperty = async (e) => {
    e.preventDefault()

    const fD = new FormData()
    fD.append("name", inputs?.name)
    fD.append("propertytypeId", inputs?.propertytypeId)
    fD.append("propertycategoryId", inputs?.propertycategoryId)
    fD.append("propertyId", inputs?.propertyId)
    fD.append("phoneNumber", inputs?.phoneNumber)
    fD.append("address", inputs?.address)
    fD.append("country", inputs?.country)
    fD.append("state", inputs?.state)
    fD.append("suburb", inputs?.suburb)
    fD.append("latLong", inputs?.latLong)

    const response = await postV2API("/maintenance/property", fD)
    setModalOpen(false)
    clearInputs()
    getProperty()
  }

  const updateProperty = async (e) => {
    e.preventDefault()
    const fD = new FormData()
    fD.append("name", title)
    fD.append("propertytypeId", propertyTypeId)
    fD.append("propertycategoryId", propertyCategoryId)
    fD.append("propertyId", propertyId)
    fD.append("phoneNumber", phoneNumber)
    fD.append("address", address)
    fD.append("country", country)
    fD.append("state", state)
    fD.append("suburb", suburb)
    fD.append("latLong", latLong)

    const response = await putV2API(`/maintenance/property/${_id}`, fD)
    setEditModal(false)
    getProperty()
    setInputs("")
    setPropertyCategoryId("")
    setPropertyId("")
    setPropertyTypeId("")
    setPhoneNumber("")
    setAddress("")
    setCountry("")
    setState("")
    setSuburb("")
    setLatLong("")
  }

  const getPropertyCategory = async () => {
    try {
      const response = await getV2API("/maintenance/property/category")
      if (response) {
        setPropertyCategoryList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }

  const getPropertyTypes = async () => {
    try {
      const response = await getV2API("/maintenance/property/type")
      if (response) {
        setPropertyTypeList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }


  const getProperty = async () => {
    try {
      const response = await getV2API("/maintenance/property")
      if (response) {
        setPropertyList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }

  const deleteProperty = async (id) => {
    try {
      const response = await deleteV2API(`/maintenance/property/${id}`)
      if (response) {
        getProperty()
      }
    } catch (error) {
      console.log(error, "error in api");
    }
  }


  // -------------------------------------------- Property  CRUD Ends -------------------------------------------------------------

  useEffect(() => {
    getProperty()
    getPropertyTypes()
    getPropertyCategory()
  }, [])

  const clearInputs = () => {
    setInputs((prev) => ({
      ...prev,
      name: "",
      propertytypeId: "",
      propertycategoryId: "",
      propertyId: '',
      phoneNumber: "",
      address: "",
      country: "",
      state: "",
      suburb: "",
      latLong: ""
    }))
  }

  return (
    <div>

      <PageTitle title={"Property"} />


      <div>
        <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Property
        </Button>
      </div>

      <div style={{ marginTop: "50px" }}>

        <TableContainer component={Paper} sx={{ mx: "0.8rem", width: "100%", my: "6rem" }}>
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Property
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Property Category
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Property Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  propertyId
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  phoneNumber
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  address
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  state
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  suburb
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Country
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  lat long
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                [...propertyList]?.reverse().slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.name}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.propertycategoryId?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.propertytypeId?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.propertyId}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.phoneNumber}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.address}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.state}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.suburb}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.country}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.latLong}
                    </TableCell>

                    <TableCell align="left" sx={{ display: "flex" }} >
                      <Button size="small" className="btn-div" variant="outlined" color="info"
                        onClick={(e) => editProperty(e, item?._id)}
                      >
                        <EditIcon className="" />
                      </Button>
                      <Button size="small" className="btn-div" variant="outlined" color="error"
                        onClick={() => deleteProperty(item?._id)}
                      >
                        <DeleteIcon className="" />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              {
                !propertyList &&
                <EmptyTable colSpan={9} />
              }
            </TableBody>
            <TableFooter>
              <TableRow>

                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </div> */}
        </TableContainer>

      </div>


      <div>
        <Modal
          open={modalOpen}
        >
          <Box sx={style}>
            {/* <h6>Close</h6> */}
            <div className='box-title'>
              <h2>Add Property</h2>
            </div>
            <form onSubmit={createProperty}>
              <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                <TextField variant='outlined' label="Property Name" className='box-input' name='name' onChange={handleChange} />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs?.propertytypeId}
                    name='propertytypeId'
                    label="Property Type"
                    onChange={handleChange}
                  >
                    {
                      propertyTypeList?.map((property) => {
                        return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                      })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs?.propertycategoryId}
                    name='propertycategoryId'
                    label="Property Type"
                    onChange={handleChange}
                  >
                    {
                      PropertyCategoryList?.map((property) => {
                        return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                      })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                <TextField variant='outlined' label="Property Id" className='box-input' name='propertyId' onChange={handleChange} />
                <TextField variant='outlined' label="Phone Number" className='box-input' name='phoneNumber' onChange={handleChange} />
                <TextField variant='outlined' label="Address" className='box-input' name='address' onChange={handleChange} />
                <TextField variant='outlined' label="Country" className='box-input' name='country' onChange={handleChange} />
                <TextField variant='outlined' label="State" className='box-input' name='state' onChange={handleChange} />
                <TextField variant='outlined' label="Suburb" className='box-input' name='suburb' onChange={handleChange} />
                <TextField variant='outlined' label="latLong" className='box-input' name='latLOng' onChange={handleChange} />
              </div>
              <div className='box-button-group'>
                <Button variant='contained' type='submit'>Submit</Button>
                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>


      <div>
        <Modal
          open={editModal}

        >
          <Box sx={style}>
            <PageTitle title={"Update Property"} />

            <form onSubmit={updateProperty}>
              <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                <TextField variant='outlined' label="Property Name" value={title} className='box-input' name='name' onChange={(e) => setTitle(e.target.value)} />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={propertyTypeId}
                    name='propertytypeId'
                    label="Property Type"
                    onChange={(e) => setPropertyTypeId(e.target.value)}
                  >
                    {
                      propertyTypeList?.map((property) => {
                        return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                      })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={propertyCategoryId}
                    name='propertycategoryId'
                    label="Property Type"
                    onChange={(e) => setPropertyCategoryId(e.target.value)}
                  >
                    {
                      PropertyCategoryList?.map((property) => {
                        return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                      })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                <TextField variant='outlined' label="Property Id" className='box-input' value={propertyId} name='propertyId' onChange={(e) => setPropertyId(e.target.value)} />
                <TextField variant='outlined' label="Phone Number" className='box-input' value={phoneNumber} name='phoneNumber' onChange={(e) => setPhoneNumber(e.target.value)} />
                <TextField variant='outlined' label="Address" className='box-input' value={address} name='address' onChange={(e) => setAddress(e.target.value)} />
                <TextField variant='outlined' label="Country" className='box-input' value={country} name='country' onChange={(e) => setCountry(e.target.value)} />
                <TextField variant='outlined' label="State" className='box-input' value={state} name='state' onChange={(e) => setState(e.target.value)} />
                <TextField variant='outlined' label="Suburb" className='box-input' value={suburb} name='suburb' onChange={(e) => setSuburb(e.target.value)} />
                <TextField variant='outlined' label="latLong" className='box-input' value={latLong} name='latLOng' onChange={(e) => setLatLong(e.target.value)} />
              </div>
              <div className='box-button-group'>
                <Button variant='contained' type='submit'>Submit</Button>
                <Button variant='outlined' onClick={() => setEditModal(false)} >Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>



    </div>
  )
}

export default Property