/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Grid,
  FormControl,
  Skeleton,
  Button,
  Alert,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormLabel
} from "@mui/material";
import LocalTimeSelector from "../../common/LocalTimeSelector";
import { red } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BasicSelector from "../../common/Selector";
import TableFooter from "@mui/material/TableFooter";
import LocalDateSelector from "../../common/LocalDateSelector";
import PageTitle from "../../common/PageTitle";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader"
import { deleteAPI, getAPI, getExportAPI, postAPI, putAPI } from "../../network";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CompanyContext } from "../../../context";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaceIcon from '@mui/icons-material/Place';
import EmptyTable from "../../common/EmptyTable";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthority, tableHeader, tableData, formatDate, timeFormat, formatDatePost, validation } from "../../utils";
import "./style.css";
import { FilterList } from "@mui/icons-material";
import { getCompanies } from "../../../features/company/companyAPI";
import { BASE_URL, axiosInstance } from '../../../utils/axiosSetup';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


export default function VisitorList() {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const [companyId] = useContext(CompanyContext)
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [markers, setMarkers] = useState([]); // Custom markers
  const [siteMarkers, setSiteMarkers] = useState([]); // Custom markers

  const [deleteId, setDeleteId] = useState('');
  const [open, setOpen] = useState(false)
  const [openV, setOpenV] = useState(false)
  const [openCheckout, setOpenCheckout] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [bookId, setBookId] = useState('')
  const [openMap, setOpenMap] = useState(false)
  const [position1, setPosition1] = useState([-33.93493568576685, 151.06907095273533]);
  const [position2, setPosition2] = useState([-33.935, 151.07]);
  const [loader, setLoader] = useState(false)
  const [tasks, setTasks] = useState([])
  const [sites, setSites] = useState([])
  const [companies, setCompanies] = useState([])
  const [emailList, setEmailList] = useState([])
  const [company, setCompany] = useState('')
  const [urlF, setUrlF] = useState('')
  const [lat, setLat] = useState(null)
  const [long, setLong] = useState(null)
  const [visitorType, setVisitorType] = useState('')
  const [ids, setIds] = useState('')

  const [page, setPage] = useState(0);
  const [perPages, setPerPages] = useState([5, 10, 25, 50]);
  const [perPage, setPerPage] = useState(10)
  const [visitorDataList, setVisitorDataList] = useState(null)
  const [reportableIssue, setReportableIssue] = useState('')
  const [injuryReported, setInjuryReported] = useState('')
  const [taskStatus, setTaskStatus] = useState('')

  const [filter, setFilter] = useState({
    companyId: '',
    siteId: '',
    startDate: null,
    endDate: null,
    email: '',
  })

  const clearFilter = () => {
    setFilter({
      companyId: '',
      siteId: '',
      startDate: null,
      endDate: null,
      email: '',
    })

    setTasks([])
  }
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleBulkUpload = async (e) => {
    const formData = new FormData();
    formData.append('picture', file);

    try {
      let data = await postAPI(`/visitor/auth/bulk-register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      console.log('Upload successful:', data);
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  const { handleSubmit, control,
    formState: { errors },
    reset, setValue
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      company: "",
      site: "",
      date: new Date().toISOString().split('T')[0],
      checkIn: null,
      checkOut: null,
      lat: lat,
      lng: long,
      hostEmail: "",
      securityPass: "",
      visitorType: "",
      purpose: "",
      ids: "",

    },
  });

  const handleClose = () => {
    setOpenV(false);
    setOpenEdit(false);
    setOpenCheckout(false);
    setOpenMap(false);
  };

  const handleMapData = (task) => {

    setMarkers([
      {
        "coordinates": [
          task.lat,
          task.lng
        ],
        "name": task.user.firstname + ' ' + task.user.lastname,
        "email": task.user.email
      }
    ]);

    setSiteMarkers([
      {
        "coordinates": [
          task.siteId.latitude,
          task.siteId.longitude
        ],
        "name": task.siteId.name,
        "email": task.siteId.address
      }
    ]);

    setPosition1([task.lat, task.lng])
    setPosition2([task.siteId.latitude, task.siteId.longitude])

    setOpenMap(true);

  }

  const onSubmit = async (form) => {
    // console.log('form++++++++++++++++++', form)
    form.companyId = company
    // form.company = company
    if (validation(null, 'Name', form.firstname)) {

      return;
    }
    if (validation(null, 'Name', form.lastname)) {

      return;
    }
    if (validation('empty', 'email', form.email)) {
      return;
    }
    if (validation('empty', 'phone', form.phone)) {
      return;
    }
    else if (validation('empty', 'site', form.site)) {
      return;
    }
    else if (validation('empty', 'company', form.company)) {
      return;
    }
    else if (validation('empty', 'Date', form.date)) {
      return;
    }
    else if (validation('time', 'Check In', form.checkIn)) {
      return;
    }



    setLoader(true)
    let data = await postAPI(`/visitor/auth/register`, form)
    console.log("data post++++", data)
    if (data) {
      // getSitesInspectionList()
      handleClose();
      reset();
      setCompany('')
      if (companyId) {
        getVisitorList(companyId)
        visitorCount()

      } else {
        getVisitorList()
        visitorCount()

      }
    }
    setLoader(false)

  };
  const forcedCheckout = async () => {
    try {
      const payload = {
        injurySuffered: injuryReported,
        reportableIssue: reportableIssue,
        taskStatus,
        bookId,
      }
      let data = await postAPI(`/visitor/book/forcedClockOut`, payload)
      getVisitorList()
      visitorCount()
      setOpenCheckout(false)
    } catch (error) {

    }
  }
  // const taskData = []
  // for (let j = 0; j < tasks.length; j++) {
  //   taskData.push(tasks[j].tasks)
  // }


  // const taskD = []
  // for (let i = 0; i < taskData.length; i++) {
  //   taskD.push(taskData[i][0])
  // }

  useEffect(() => {
    let currentDate = new Date();

    if (companyId) {
      getVisitorList(companyId, null, currentDate)
    } else {
      getVisitorList(null, null, currentDate)
    }
    getSites()
    // getCompanies()
    visitorCount()
  }, []);


  const userType = localStorage.getItem('userType')


  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  // {console.log( checkAuthority('ADD_TASK') ? 'TRUE ---------' : "FALSE --------")}
  const getTasks = async (sId = null, sDate = null, eDate = null) => {

    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;

    var url = ''
    var start = true;
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `taskDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    setLoader(true)
    if (companyId) {
      let data = await getAPI(`/visitor-tasks?companyId=${companyId}` + url)
      if (data) {
        setTasks(data)
      }
    }
    else {
      let data = await getAPI(`/visitor-tasks` + url)
      if (data) {
        setTasks(data)
      }
    }

    setLoader(false)
  }
  const getVisitorList = async (cId = null, sId = null, sDate = null, eDate = null, email = null) => {

    cId = cId !== null ? cId : filter.companyId;
    sId = sId !== null ? sId : filter.siteId;
    eDate = eDate !== null ? eDate : filter.endDate;
    sDate = sDate !== null ? sDate : filter.startDate;
    email = email !== null ? email : filter.email;

    var url = ''
    var start = true;
    if (cId !== '' && cId !== null) {
      url += (start ? '?' : '&') + `company=${cId}`
      start = false;
    }
    if (sId !== '' && sId !== null) {
      url += (start ? '?' : '&') + `siteId=${sId?.id ? sId?.id : sId}`
      start = false;
    }
    if (eDate !== '' && eDate !== null) {
      url += (start ? '?' : '&') + `endDate=${formatDatePost(eDate)}`
      start = false;
    }
    if (sDate !== '' && sDate !== null) {
      url += (start ? '?' : '&') + `startDate=${formatDatePost(sDate)}`
      start = false;
    }
    if (email !== '' && email !== null) {
      url += (start ? '?' : '&') + `user=${email}`
      start = false;
    }
    setLoader(true)
    setUrlF(url)
    let data = await getAPI(`/visitor/book` + url)
    if (data) {
      setTasks(data)
      let emails = []
      for (let i = 0; i < data.length; i++) {
        let x = { label: data[i]?.user?.email, value: data[i]?.user?._id }
        emails.push(x)
      }
      setEmailList(emails)
    }

    setLoader(false)
  }

  const getSites = async (sId) => {
    setLoader(true)
    let data = await getAPI(`/sites?companyId=${sId ? sId : companyId}`)
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj.name
        obj['value'] = obj._id
        arr.push(obj)
      }
      setSites(arr)
    }
    setLoader(false)
  }

  const getCompanies = async () => {
    setLoader(true)
    const data = await getAPI(`/companies?companyId=${companyId}`);
    if (data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = data[i]
        obj['label'] = obj?.name
        obj['value'] = obj?.id
        arr.push(obj)
      }
      setCompanies(arr)
    }
    setLoader(false)
  }

  const deleteClick = (id) => {
    setDeleteId(id);
    setOpen(true)
  }

  console.log(urlF);

  const handleDelete = async (siteId = null) => {

    setLoader(true);
    let empty = await deleteAPI(`/tasks/${deleteId}`);
    if (empty) {
      setOpen(false);
      if (companyId) {
        getVisitorList(companyId)
        visitorCount()

      } else {
        getVisitorList()
        visitorCount()

      }

    }
    setLoader(false);
  }
  const AssessExp = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor_management?visitorId=${id}`, `Visitor List`)
    setLoader(false)
  }
  const AssessExp2 = async (id) => {
    setLoader(true)
    let data = await getExportAPI(`${BASE_URL}/visitor/book/export${urlF}`, `Visitor Details`)
    setLoader(false)
  }

  const visitorCount = async () => {
    setLoader(true)
    let data = await axiosInstance.get(`/visitor/book/count`);
    setVisitorDataList(data.data)
  }

  const latLongHandler = (event) => {
    let s = sites.find(site => site._id === event.target.value);
    setValue("lat", s.latitude);
    setValue("lng", s.longitude);
    setLat()
    setLong()
    console.log(s)
    // form.lat=s.latitude
  }

  function convertTimestampToAMPM(timestampString) {
    // Create a new Date object with the timestamp string
    if (!timestampString) {
      return ""
    }
    var date = new Date(timestampString);

    // Get hours, minutes, and seconds
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();

    // Determine AM/PM
    var period = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12

    // Add leading zero to minutes if less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Construct the formatted time string
    var formattedTime = hours + ':' + minutes + ' ' + period;

    return formattedTime;
  }
  const [formData, setFormData] = useState({
    visitorType: '',
    purpose: '',
    ids: '',
    securityPass: '',
    id: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const fillDetails = (d) => {
    setFormData({
      ...formData,
      visitorType: d?.visitorType,
      purpose: d?.purpose,
      ids: d?.ids,
      securityPass: d?.securityPass,
      id: d?._id,
    });
  }
  const handleEdit = async () => {
    await putAPI(`/visitor/auth/edit/${formData.id}`, formData)
    setOpenEdit(false)
    getVisitorList()
  };

  return (
    <Box>
      <Loader loader={loader} />
      <PageTitle title="Visitor Management" />

      <Box sx={{ marginLeft: "20px" }}>
        <FormControl
          sx={{
            width: "100%"

          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <div><b>Current Date Visitor Overview</b></div>


          </Box>
          <Grid sx={{ my: "1rem", justifyContent: 'space-between', display: "flex", padding: "2px", alignItems: 'center' }} spacing={3} width={"100%"}>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "green", color: "white" }}>
              <PeopleAltIcon /> {visitorDataList?.total} Total Visitors
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "lightgreen" }}>
              <PeopleAltIcon /> {visitorDataList?.active} Active Visitors
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "orange" }}>
              <PeopleAltIcon /> {visitorDataList?.clockOut} Checked Out
            </Grid>
            <Grid sx={{ width: '22%', height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px !important', m: '10', fontWeight: '500', borderRadius: '5px !important', boxShadow: '0px 2px 4px 3px rgba(0, 0, 0, 0.08)', backgroundColor: "red", color: 'white' }}>
              <PlaceIcon /> {visitorDataList?.mismatch} Location Mismatch
            </Grid>
          </Grid>

          {/* 
          <Grid sx={{ my: "1rem", justifyContent: 'space-between !important', alignItems: 'center' }} container spacing={3} width={"100%"}>
            <Grid>
              <b>Task Completed</b>
            </Grid>
            {
              checkAuthority('ADD_TASK') && userType == 'company' ?

                <Grid item xs={6} md={6} lg={4} sx={{ my: 2 }}>
                  <Link
                    to="/company/tasks/create"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      padding: 15,
                      fontWeight: 600,
                      borderRadius: 5,
                      textDecoration: "none",
                    }}
                  >
                    Create Task
                  </Link>
                </Grid> :
                <Grid item xs={6} md={6} lg={4} sx={{ my: 2 }}>
                  <Link
                    to="/tasks/create"
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      padding: 15,
                      fontWeight: 600,
                      borderRadius: 5,
                      textDecoration: "none",
                    }}
                  >
                    Add Task
                  </Link>
                </Grid>
            }

          </Grid> */}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", mt: '1rem' }}
          >
            <div><b>Visitor Register</b></div>

            <div style={{ display: 'flex', gap: "10px", padding: "5px 0px" }}>
              <Button variant="contained" style={{ backgroundColor: "#75859D", padding: "20px 10px" }} sx={{ height: 30 }}
                onClick={() => setOpenV(true)}>
                <AddCircleIcon /> &nbsp; &nbsp;
                Add Visitor
              </Button>
              {/* <Button variant="contained" style={{ backgroundColor: "grey" }} sx={{ height: 30, m: 2 }}>

                <input type="file" name="picture" onChange={handleFileChange} />
                <button onClick={() => handleBulkUpload()}>Upload</button>

              </Button> */}
              <Button variant="contained" style={{ backgroundColor: "#75859D", padding: "20px 10px" }} sx={{ height: 30 }} onClick={() => AssessExp2()}>
                <GetAppRoundedIcon /> &nbsp; &nbsp;
                Export
              </Button>
            </div>
          </Box>


          <Grid sx={{ my: "1rem", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} spacing={3} width={"100%"}>


            {
              checkAuthority('VIEW_TASKS') &&
              <div style={{ width: "100%", justifyContent: "start", alignItems: "center", gap: "20px", display: "flex" }}>

                <Grid item xs={6} md={6} lg={2} sx={{ width: "100%" }}>

                  <BasicSelector
                    disableAll={true}
                    options={sites}
                    selectorHight={"61px"}
                    value={filter.siteId}
                    name={"Select Site"}
                    selectorWidth={"100%"}

                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        siteId: e.target.value
                      })
                      getVisitorList(null, e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={2} sx={{ width: "100%" }}>

                  <BasicSelector
                    disableAll={true}
                    options={emailList}
                    selectorHight={"61px"}
                    value={filter.email}
                    name={"Visitor Email"}
                    selectorWidth={"100%"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    onChange={(e) => {

                      setFilter({
                        ...filter,
                        email: e.target.value
                      })
                      getVisitorList(null, null, null, null, e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={3} sx={{ width: "100%" }}>
                  <LocalDateSelector
                    title="Start Date "
                    value={filter.startDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        startDate: date
                      })
                      getVisitorList(null, null, date, null)
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={3} sx={{ width: "100%" }}>
                  <LocalDateSelector
                    title="End Date "
                    value={filter.endDate}

                    onChange={(date) => {
                      setFilter({
                        ...filter,
                        endDate: date
                      })
                      getVisitorList(null, null, null, date)
                    }}
                  />
                </Grid>
                <Grid >
                  <Button variant="contained" style={{ backgroundColor: "#75859D", width: "150px" }} sx={{ height: 56 }}
                    onClick={() => clearFilter()}>
                    Clear Filter
                  </Button>
                </Grid>

              </div>
            }

          </Grid>
        </FormControl>
        {
          checkAuthority('VIEW_TASKS') &&
          <Box display="flex" sx={{ my: "3rem" }}>
            <TableContainer component={Paper} style={{ overflow: "auto" }}>

              <Table
                sx={{ minWidth: 650, overflowX: "auto" }}
                aria-label="custom pagination table"
                className="responsive-table"
              >
                <TableHead sx={{ overflowX: "auto" }}>
                  <TableRow className="table-header">
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Date
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Site Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Guest Name
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Guest Company
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Guest Email
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Guest Phone
                    </TableCell>
                    <TableCell align="left" component="th" sx={tableHeader}>
                      Induction Expiry
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Check In
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Check Out
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Visitor Type
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Purpose
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Check In Location
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Host Email
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      IDs
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Security Pass
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Injury Reported
                    </TableCell>
                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                      Task Completed
                    </TableCell> */}
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Reportable Issue
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Task Status
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Action
                    </TableCell>
                    <TableCell align="center" component="th" sx={tableHeader}>
                      Visitor Status
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflowX: "auto" }}>


                  {tasks.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" sx={tableData}>
                        {task?.updatedAt?.split("T")[0]}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.siteId?.name}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {`${task?.user?.firstname} ${task?.user?.lastname}`}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.company}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.user?.email}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.user?.phone}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.inductionExpiry ? task?.inductionExpiry.split("T")[0] : ''}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {convertTimestampToAMPM(task?.checkIn)}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {convertTimestampToAMPM(task?.checkOut)}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.visitorType}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.purpose}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>

                        {task?.isMatch === true ?
                          <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                          <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                        }
                        <i>
                          {task?.isMatch ? null : `${task?.distance} km away`}
                        </i>
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.hostEmail}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.ids}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.securityPass}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.clockOutData?.injurySuffered}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.clockOutData?.reportableIssue}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {task?.clockOutData?.taskStatus}
                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        <Button size="small" className="btn-div" variant="outlined" color="info" onClick={() => {
                          setOpenEdit(true)
                          fillDetails(task)
                        }}>
                          <EditIcon className="" />
                        </Button>

                      </TableCell>
                      <TableCell align="left" sx={tableData}>
                        {
                          task?.checkOutStatus && !task?.forcedCheckOutStatus
                            ?
                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }}>Self Checked Out</Button>
                            :
                            null
                        }
                        {
                          task.checkOutStatus && task?.forcedCheckOutStatus
                            ?
                            <Button variant="outlined" color="error">Forced Check Out</Button>
                            :
                            null
                        }

                        {
                          !task.checkOutStatus
                            ?
                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => {
                              setOpenCheckout(true)
                              setBookId(task._id)
                            }
                            }>Check Out</Button>
                            :
                            null
                        }
                      </TableCell>


                    </TableRow>

                  ))}

                  {
                    tasks.length === 0 &&
                    <EmptyTable colSpan={6} />
                  }
                </TableBody>
                <TableFooter>
                  <TableRow >

                    <TablePagination
                      align="right"
                      rowsPerPageOptions={perPages}
                      colSpan={7}
                      count={tasks.length}
                      rowsPerPage={perPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        }

      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
        <DialogTitle sx={{ mb: 4, textAlign: "center" }}>Delete Task</DialogTitle>

        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <h3 style={{ textAlign: 'left', fontWeight: 'bold' }}>Do you want's to delete this task</h3>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 4 }}>
          <Button onClick={handleDelete} variant="contained" color="error">Delete</Button>
          <Button onClick={() => setOpen(false)} variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openV} onClose={handleClose}>
        {/* <DialogTitle align="center">Add Visitor</DialogTitle> */}
        <Typography align="center" style={{ marginTop: "10px" }} variant="h5">Add Visitor</Typography>
        <DialogContent height={"500px"} sx={{ zIndex: 10 }}>
          <Grid
            container
            spacing={2}
            mt={3}
            component="form">
            <Grid item xs={12} display="flex">

              <Controller
                name={"firstname"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Visitor First Name"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.name}
                    helperText={
                      errors.name ? errors.name?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lastname"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Visitor Last Name"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.name}
                    helperText={
                      errors.name ? errors.name?.message : null
                    }
                  />
                )}
              />
            </Grid>


            {/* <Grid item xs={12} display="flex" flexDirection="column" m={0}>

                <InputLabel id="gender-label">Report Category</InputLabel>
                <Controller
                  name={"reportTypeId"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (

                    <Select
                      fullWidth
                      labelId="gender-label"
                      variant="outlined"
                      required
                      onChange={onChange}
                      value={value}
                      error={!!errors.reportTypeId}
                      helperText={errors.reportTypeId ? errors.reportTypeId?.message : null}
                    > */}
            {/* <MenuItem value={"HSW "}>HSW </MenuItem>
                        <MenuItem value={"Compliance"}>Compliance</MenuItem>
                        <MenuItem value={"Operational"}>Operational</MenuItem>
                        <MenuItem value={"General "}>General </MenuItem>
                        <MenuItem value={"Mandatory"}>Mandatory</MenuItem>
                        <MenuItem value={"Other "}>Other </MenuItem> */}
            {/* {allreports.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.name}
                        </MenuItem>
                      ))} */}
            {/* </Select>
                  )}
                />
              </Grid> */}



            <Grid item xs={12} display="flex">
              <Controller
                name={"email"}
                control={control}
                rules={{
                  required: true,
                  min: {
                    message: "Minimum Value Is Zero",
                    value: 0,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    onChange={onChange}
                    value={value}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email?.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <Controller
                name={"phone"}
                control={control}
                rules={{
                  required: true,
                  min: {
                    message: "Minimum Value Is Zero",
                    value: 0,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    type="number"
                    required
                    onChange={onChange}
                    value={value}
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone?.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex">
              <Controller
                name={"company"}
                control={control}
                rules={{
                  required: true,
                  min: {
                    message: "Add Company name",
                    value: 0,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Company"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    error={!!errors.company}
                    helperText={errors.company ? errors.company?.message : null}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <Controller
                name={"company"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    error={!!errors.company}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      companies.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>{item?.label}</MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
              <BasicSelector
                disableAll={true}
                options={companies}
                selectorHight={"53px"}
                value={company}
                name={"Select Company"}
                selectorWidth={"100%"}
                onChange={(e) => {

                  setCompany(e.target.value)
                  getVisitorList(e.target.value)
                  getSites(e.target.value)
                }}
              />
            </Grid> */}

            <Grid item xs={12} display="flex" gap="10px" flexDirection="column" m={0}>

              <InputLabel id="gender-label" style={{ marginLeft: '3px' }}>Sites</InputLabel>
              <Controller
                name={"site"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select

                    value={value}

                    onChange={(event) => {
                      onChange(event);
                      latLongHandler(event)
                    }}
                    placeholder='Sites'
                    displayEmpty
                    // error={!!errors.sites}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    {
                      sites.map((item, index) => (
                        <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Date</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <FormControl >
                <Controller
                  name="date"
                  control={control}
                  defaultValue={new Date().toISOString().split('T')[0]}
                  render={({ field: { onChange, value } }) => (
                    <LocalDateSelector
                      label="none"
                      onChange={onChange}
                      value={value}
                      inputFormat="YYYY-MM-DD"

                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Check In</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <FormControl >
                <Controller
                  name="checkIn"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalTimeSelector
                      label="none"
                      onChange={onChange}
                      value={value}

                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Latitude</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lat"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    // label="Latitude"
                    variant="outlined"
                    type="text"
                    required
                    defaultValue={lat}
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lat}
                    helperText={
                      errors.lat ? errors.lat?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Longitude</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"lng"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    // label="Longitude"
                    variant="outlined"
                    type="text"
                    required
                    defaultValue={long}
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lng}
                    helperText={
                      errors.lng ? errors.lng?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Host Email</InputLabel>

            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"hostEmail"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    // label="Longitude"
                    variant="outlined"
                    type="email"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lng}
                    helperText={
                      errors.lng ? errors.lng?.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" gap="10px" flexDirection="column" m={0}>

              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Visitor Type *</InputLabel>
              <Controller
                name={"visitorType"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select

                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      setVisitorType(event.target.value)
                    }}
                    displayEmpty
                    // error={!!errors.sites}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    <MenuItem value="Guest">Guest</MenuItem>
                    <MenuItem value="Contractor">Contractor</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" gap="10px" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Purpose *</InputLabel>

              {
                visitorType === 'Guest' ? <Controller
                  name={"purpose"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (
                    <Select

                      value={value}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      displayEmpty
                      // error={!!errors.sites}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    >

                      <MenuItem value={'Meeting'}>Meeting</MenuItem>
                      <MenuItem value={'Event'}>Event</MenuItem>
                      <MenuItem value={'Business'}>Business</MenuItem>
                      <MenuItem value={'Deliveries'}>Deliveries</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                  )}
                />
                  : null
              }
              {
                visitorType === 'Contractor' ? <Controller
                  name={"purpose"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (
                    <Select

                      value={value}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      displayEmpty
                      // error={!!errors.sites}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    >
                      <MenuItem value={'Preventative Maintenance'}>Preventative Maintenance</MenuItem>
                      <MenuItem value={'Reactive Maintenance'}>Reactive Maintenance</MenuItem>
                      <MenuItem value={'Project'}>Project</MenuItem>
                      <MenuItem value={'Quoted Job'}>Quoted Job</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                  )}
                />
                  : null
              }
              {
                !visitorType ? <Controller
                  name={"purpose"}
                  control={control}
                  rules={{
                    required: true,
                  }}

                  render={({ field: { onChange, value } }) => (
                    <Select

                      value={value}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      displayEmpty
                      // error={!!errors.sites}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                    >
                      <MenuItem value={null}>Please Select Visitor Type</MenuItem>

                    </Select>
                  )}
                />
                  : null
              }

            </Grid>
            <Grid item xs={12} display="flex" gap="10px" flexDirection="column" m={0}>

              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>IDs *</InputLabel>
              <Controller
                name={"ids"}
                control={control}
                rules={{
                  required: true,
                }}

                render={({ field: { onChange, value } }) => (
                  <Select

                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      setIds(event.target.value)
                    }}
                    displayEmpty
                    // error={!!errors.sites}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderColor: "#707070" }}
                  >

                    <MenuItem value="Passport">Passport</MenuItem>
                    <MenuItem value="Drivers License">Drivers License </MenuItem>
                    <MenuItem value="Medicare">Medicare </MenuItem>
                    <MenuItem value="Event">Event </MenuItem>
                    <MenuItem value="Other">Other </MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column" m={0}>
              <InputLabel id="gender-label" style={{ marginLeft: "5px" }}>Security Pass </InputLabel>

            </Grid>
            <Grid item xs={12} display="flex">

              <Controller
                name={"securityPass"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    // label="Longitude"
                    variant="outlined"
                    type="text"
                    required
                    onChange={onChange}
                    value={value}
                    sx={{ borderColor: red[100] }}
                    error={!!errors.lng}
                    helperText={
                      errors.lng ? errors.lng?.message : null
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            my={3}
            component="form"
          >
            <Grid item xs={7} justifyContent="space-around" display="flex">
              <Button
                disabled={false}
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button disabled={false} onClick={handleSubmit(onSubmit)}>
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={openCheckout} onClose={handleClose}>
        {/* <DialogTitle align="center">Add Visitor</DialogTitle> */}
        <Typography align="center" variant="h5">Checkout</Typography>
        <DialogContent height={"500px"} sx={{ zIndex: 10 }}>

          <Box sx={{ minWidth: 240, my: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Injury Reported</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Injury Reported"
                value={injuryReported}
                onChange={(e) => setInjuryReported(e.target.value)}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 240, my: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reportable Issue</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Reportable Issue"
                value={reportableIssue}
                onChange={(e) => setReportableIssue(e.target.value)}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 240, my: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Task Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Task Status"
                value={taskStatus}
                onChange={(e) => setTaskStatus(e.target.value)}
              >
                <MenuItem value={'Completed'}>Completed</MenuItem>
                <MenuItem value={'Partially Completed'}>Partially Completed</MenuItem>
                <MenuItem value={'Reschedule'}>Reschedule</MenuItem>
                <MenuItem value={'On Hold'}>On Hold</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            my={3}
            component="form"
          >
            <Grid item xs={7} justifyContent="space-around" display="flex">
              <Button
                disabled={false}
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button disabled={false} onClick={() => forcedCheckout()}>
                Checkout
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleClose}>
        {/* <DialogTitle align="center">Add Visitor</DialogTitle> */}
        <Typography align="center" variant="h5">Edit</Typography>
        <DialogContent height={"500px"} sx={{ zIndex: 10 }}>

          <Box
            component="form"
            onSubmit={handleEdit}
            sx={{
              '& .MuiTextField-root': { m: 2, width: '25ch' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            {/* Visitor Type Dropdown */}
            <FormControl fullWidth sx={{ m: 2, width: '25ch' }}>
              <InputLabel id="visitor-type-label">Visitor Type</InputLabel>
              <Select
                labelId="visitor-type-label"
                name="visitorType"
                value={formData.visitorType}
                onChange={handleChange}
                label="Visitor Type"
              >
                <MenuItem value="Guest">Guest</MenuItem>
                <MenuItem value="Contractor">Contractor</MenuItem>
              </Select>
            </FormControl>


            {formData.visitorType === 'Contractor' ?
              <FormControl fullWidth sx={{ m: 2, width: '25ch' }}>
                <InputLabel id="purpose-label">Purpose</InputLabel>
                <Select
                  labelId="purpose-label"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                  label="Purpose"
                >
                  <MenuItem value={'Preventative Maintenance'}>Preventative Maintenance</MenuItem>
                  <MenuItem value={'Reactive Maintenance'}>Reactive Maintenance</MenuItem>
                  <MenuItem value={'Project'}>Project</MenuItem>
                  <MenuItem value={'Quoted Job'}>Quoted Job</MenuItem>
                  <MenuItem value={'Others'}>Others</MenuItem>
                </Select>
              </FormControl>
              : null
            }
            {
              formData.visitorType === 'Guest' ?
                <FormControl fullWidth sx={{ m: 2, width: '25ch' }}>
                  <InputLabel id="purpose-label">Purpose</InputLabel>

                  <Select
                    labelId="purpose-label"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    label="Purpose"
                  >
                    <MenuItem value={'Meeting'}>Meeting</MenuItem>
                    <MenuItem value={'Event'}>Event</MenuItem>
                    <MenuItem value={'Business'}>Business</MenuItem>
                    <MenuItem value={'Deliveries'}>Deliveries</MenuItem>
                    <MenuItem value={'Others'}>Others</MenuItem>
                  </Select>
                </FormControl>
                : null}
            <FormControl fullWidth sx={{ m: 2, width: '25ch' }}>
              <InputLabel id="purpose-label">IDs</InputLabel>

              <Select
                labelId="purpose-label"
                name="ids"
                value={formData.ids}
                onChange={handleChange}
                label="IDs"
              >

                <MenuItem value="Passport">Passport</MenuItem>
                <MenuItem value="Drivers License">Drivers License </MenuItem>
                <MenuItem value="Medicare">Medicare </MenuItem>
                <MenuItem value="Event">Event </MenuItem>
                <MenuItem value="Other">Other </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Security Pass"
              name="securityPass"
              value={formData.securityPass}
              onChange={handleChange}
              variant="outlined"
            />

          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            my={3}
            component="form"
          >
            <Grid item xs={7} justifyContent="space-around" display="flex">
              <Button
                disabled={false}
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button disabled={false} onClick={() => handleEdit()}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={openMap} onClose={handleClose}>
        {/* <DialogTitle align="center" >Visitor Location</DialogTitle> */}

        <MapContainer center={position2} zoom={22} style={{ height: '80vh', width: '120vh', marginButton: '10px' }}>
          <TileLayer
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {markers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {siteMarkers.map((marker, index) => (
            <Marker key={index} position={marker?.coordinates}>
              <Popup>{marker.name}
                <br />
                {marker?.email}
              </Popup>
            </Marker>
          ))}
          {/* <Polyline positions={[siteMarkers.coordinates, markers.coordinates]} color="red" /> */}
          <Polyline positions={[position1, position2]} color="red" />

        </MapContainer>

      </Dialog>
    </Box >

  );
}
