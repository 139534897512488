import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import './ViewButton.css'
import { getV2API } from '../../../network/index.v2';
import { tableData } from '../../../utils';
import EmptyTable from '../../../common/EmptyTable';

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}


const ViewQuotation = () => {

    const [quotationList, setQuotationList] = useState([])

       // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


       const [page, setPage] = useState(0)
       const [perPages, setPerPages] = useState([10, 25, 50]);
       const [perPage, setPerPage] = useState(10)
   
       const handleChangePage = (event, newPage) => {
           setPage(newPage);
       };
   
   
       const handleChangeRowsPerPage = (event) => {
           console.log(event.target.value)
           setPerPage(event.target.value)
           setPage(0);
       };
   
   
       // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

    const getQuotations = async () => {
        try {
            const response = await getV2API("/maintenance/quotation/clientApplications")
            if (response) {
                setQuotationList(response)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getQuotations()
    }, [])

    return (
        <div>
            <div>
                <div className='export-button' >
                    <div style={{ display: "flex", gap: "10px" }}>
                        <p className='titles'>client</p>
                        <p className='titles'>Site</p>
                        <p className='titles'>Quote Status</p>
                        <p className='titles'>WO Status</p>
                        {/* <p className='titles'>Work Permit Status</p>
                        <p className='titles'>Quotations Status</p>
                        <p className='titles'>Service Categ 1</p>
                        <p className='titles'>Service Categ 2</p>
                        <p className='titles'>Service Categ 3</p>
                        <p className='titles'>Selected Date Range</p> */}
                    </div>
                    <Button variant="contained" style={{ backgroundColor: "grey", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }} sx={{ height: 30, m: 2 }}>
                        <GetAppRoundedIcon /> &nbsp; &nbsp;
                        Export
                    </Button>
                </div>

                <div >
                    <Box display="flex" sx={{ my: "3rem" }}>
                        <TableContainer component={Paper}>

                            <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="custom pagination table"
                                className="responsive-table"
                            >
                                <TableHead>
                                    <TableRow className="table-header">
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            client
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Site
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Quote Status(Applied At)
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Work Order Number
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            WO Status
                                        </TableCell>
                                        <TableCell align="left" component="th" sx={tableHeader}>
                                            Defect Type
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Field user notes
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            Action
                                        </TableCell>
                                        <TableCell align="center" component="th" sx={tableHeader}>
                                            View
                                        </TableCell>
                                      

                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                {quotationList.slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.clientName}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.site}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.appliedAt?.split("T")[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.company}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.email}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.user?.phone}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.notes}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.checkOut?.split('T')[0]}
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            {task?.purpose}
                                        </TableCell>
                                        {/* <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                            No
                                        </TableCell>
                                        
                                        <TableCell align="left" sx={tableData}>
                                            Non Critical
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                            Pending
                                        </TableCell> */}
                                        {/* <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell> */}

                                    </TableRow>

                                ))}

                                {
                                    quotationList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                                <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={quotationList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ViewQuotation