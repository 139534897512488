import React, { useEffect, useState } from 'react'
// import property from "./AddProperty.json"
import SiteDetailCard from '../../../components/sites/DetailCard'
import "./AddProperty.css"
import { Box, Button, Modal, TextField } from '@material-ui/core'
// import EmptyTable from '../../../common/EmptyTable'
// import { Handler } from 'leaflet'
import { checkAuthority } from '../../../utils'

export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}


const AddProperty = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    // const [isModalOpen2, setIsModalOpen2] = useState(false)
    // const [isModalOpen3, setIsModalOpen3] = useState(false)
    const [categoryModal, setCategoryModal] = useState(false)
    const [propertyModal, setPropertyModal] = useState(false)
    const [cards, setCards] = useState([])
    // const segments = window.location?.pathname;
    // const handleOpenModal = (modalType) => {

    //     console.log(modalType);
    //     switch (modalType) {
    //         case 'property':
    //             setPropertyModal(true);
    //             break;
    //         case 'category':
    //             setCategoryModal(true);
    //             break;
    //         default:
    //             setIsModalOpen(true);
    //             break;
    //     }
    // };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: "2px"
    };

    const handleClick = (e, i) => {
        console.log(i, "----i");
        e.preventDefault()
        setIsModalOpen(true)
    }

    useEffect(() => {
        getOpenPropertyModals()
    }, [])

    const getOpenPropertyModals = () => {
        var arr = [];
        if (checkAuthority('ADD_PROPERTY_TYPE')) {
            arr.push({
                id: 1,
                title: 'Add Property Type',
                url: "/maintainance-report/property/add-property/type"
                // openModal: () => handleOpenModal('property')
            });
        }
        if (checkAuthority('ADD_PROPERTY_CATEGORY')) {
            arr.push({
                id: 2,
                title: 'Add Property Category',
                url: "/maintainance-report/property/add-property/category"

                // openModal: () => handleOpenModal('category')
            });
        }
        if (checkAuthority('ADD_PROPERTY')) {
            arr.push({
                id: 3,
                title: 'Add Property',
                url: "/maintainance-report/property/add-property/add"

                // openModal: () => handleOpenModal()
            });
        }
    
        setCards(arr);
    };

    console.log(categoryModal, "---categoryModal");
    console.log(propertyModal, "---propertyModal");
    console.log(isModalOpen, "---ismodal");
    
    return (
        <div>
            <div>
                <h1>Property Portfolio</h1>
            </div>
            <div className='datacard' onClick={() => setIsModalOpen(true)}>
                {
                    cards?.map((it, i) => {
                        return <div key={i}><SiteDetailCard title={it?.title} url={it?.url} /></div>
                    })
                }
            </div>
            <div>
                <Modal
                    open={isModalOpen}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <div className='box-title'>
                            <h2>Add Property Type</h2>
                        </div>
                        <div>
                            <TextField variant='outlined' label="Property Name" className='box-input' />
                        </div>
                        <div className='box-button-group'>
                            <Button variant='contained'>Submit</Button>
                            <Button variant='outlined' onClick={() => setIsModalOpen(false)} >Cancel</Button>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={categoryModal}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <div className='box-title'>
                            <h2>Add Property Category</h2>
                        </div>
                        <div>
                            <TextField variant='outlined' label="Enter Property Category" className='box-input' />
                        </div>
                        <div className='box-button-group'>
                            <Button variant='contained'>Submit</Button>
                            <Button variant='outlined' onClick={() => setCategoryModal(false)} >Cancel</Button>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    )
}

export default AddProperty