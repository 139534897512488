import React, { useEffect, useState } from 'react'
import PageTitle from '../../../../common/PageTitle'

import AddCircleIcon from '@mui/icons-material/AddCircle';
// import EmptyTable from '../../../../common/EmptyTable';
import { tableData, tableHeader } from '../../../../utils';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import { deleteAPI, getAPI, postAPI, putAPI } from '../../../../network';
import EmptyTable from '../../../../common/EmptyTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AddBuildingAssignee = () => {
  let companyId = localStorage.getItem('companyIdLS')
  if (companyId === null) {
    companyId = undefined
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [assigneeList, setAssigneeList] = useState([])
  const [loader, setLoader] = useState(false)
  const [inputs, setInputs] = useState({
    name: "",
    type: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: "", 
    companyId: companyId 
  })

  const [buildingInputs, setBuildingInputs] = useState({
    _id: "",
    name: "",
    type: "",
    buildingTypes: "",
    buildingName: "",
    buildingFloor: "",
    buildingSpaceType: ""
  })
  const [editModal, setEditModal] = useState(false)
  const [editMode, setEditMode] = useState(false)


  const [buildingTypeList, setBuildingTypeList] = useState([])
  const [buildingNameList, setBuildingNameList] = useState([])
  const [buildingFloorList, setBuildingFloorList] = useState([])
  const [buildingSpaceList, setBuildingSpaceList] = useState([])



  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  // ---------------------------------------------- Event Handlers Start --------------------------------------------------------------


  const onChange = async (e) => {
    e.preventDefault()

    setInputs({ ...inputs, [e.target.name]: e.target.value })

  }

  const editChange = async (e) => {
    e.preventDefault()

    setBuildingInputs({ ...buildingInputs, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (editMode) {
      editBuildingTypee()
    } else {
      postBuildingType()
      setModalOpen(false)
    }

  }

  const editBuildingType = async (e, _id) => {
    e.preventDefault()

    setEditModal(true)
    setEditMode(true)

    let data = assigneeList?.filter(item => item?._id === _id)[0]

    setBuildingInputs(data)

    setBuildingInputs({ ...data, buildingTypes: data.buildingTypes._id, buildingName: data.buildingName._id, type: data.type })

  }

  // ---------------------------------------------- Event Handlers Ends --------------------------------------------------------------

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };


  // ------------------------------------------------- Get Building Type Start  ----------------------------------------------------


  const getBuildingType = async () => {
    setLoader(true)
    const response = await getAPI(`/building/type?companyId=${companyId}`)
    if (response) {
      setBuildingTypeList(response)
    }
    setLoader(false)
  }

  const getBuildingName = async () => {
    setLoader(true)
    const response = await getAPI(`/building/name?companyId=${companyId}`)
    if (response) {
      setBuildingNameList(response)
    }
    setLoader(false)
  }

  const getBuildingFloor = async () => {
    setLoader(true)
    const response = await getAPI(`/building/floor?companyId=${companyId}`)
    if (response) {
      setBuildingFloorList(response)
    }
    setLoader(false)
  }

  const getBuildingSpace = async () => {
    setLoader(true)
    const response = await getAPI(`/building/SpaceType?companyId=${companyId}`)
    if (response) {
      setBuildingSpaceList(response)
    }
    setLoader(false)
  }

  // ------------------------------------------------- Get Building Type End  ------------------------------------------------------

  // ---------------------------------------------- Building-Floor CRUD Start ------------------------------------------------------

  const postBuildingType = async () => {
    setLoader(true)
    const response = await postAPI("/building/Assignee", inputs)
    if (response) {
      getBuildingSpaceId()
      setModalOpen(false)
      setEditMode(false)
      setInputs({
        name: "",
        type: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: "", 
        companyId: companyId 
      })
    }
    setLoader(false)
  }

  const getBuildingSpaceId = async () => {
    setLoader(true)
    const response = await getAPI("/building/Assignee")
    if (response) {
      setAssigneeList(response)
    }
    setLoader(false)
  }

  const editBuildingTypee = async () => {
    setLoader(true)
    const response = await putAPI(`/building/Assignee/${buildingInputs?._id}`, buildingInputs)
    if (response) {
      getBuildingSpaceId()
      setEditModal(false)
      setBuildingInputs({
        _id: "",
        name: "",
        type: "",
        buildingTypes: "",
        buildingName: "",
        buildingFloor: "",
        buildingSpaceType: ""
      })
    }
    setLoader(false)

  }

  const deleteBuildingType = async (_id) => {
    console.log(_id);
    setLoader(true)
    const response = await deleteAPI(`/building/Assignee/${_id}`)
    if (response) {
      getBuildingSpaceId()
    }
    setLoader(false)
  }

  // ---------------------------------------------- Building-Floor CRUD Ends -------------------------------------------------------

  useEffect(() => {
    getBuildingSpaceId()
    getBuildingSpace()
    getBuildingFloor()
    getBuildingName()
    getBuildingType()
  }, [])
  return (
    <Grid>

      <PageTitle title={"Building Assignee"} />


      <Grid>
        <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Assignee
        </Button>
      </Grid>

      <Grid style={{ marginTop: "50px" }}>

        <TableContainer component={Paper} sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}>
          {/* <Grid style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Assignee Email
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Assignee Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Building Name
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                [...assigneeList]?.reverse().slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>

                    <TableCell align="left" sx={tableData}>
                      {item?.name}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.type}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingTypes?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.buildingName.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      <Button size="small" className="btn-Grid" variant="outlined" color="info" onClick={(e) => editBuildingType(e, item?._id)}>
                        <EditIcon className="" />
                      </Button>
                      <Button size="small" className="btn-Grid" variant="outlined" color="error" onClick={() => deleteBuildingType(item?._id)}>
                        <DeleteIcon className="" />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              {
                !buildingFloorList &&
                <EmptyTable colSpan={9} />
              }
            </TableBody>
            <TableFooter>
              <TableRow>

                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </Grid> */}
        </TableContainer>

      </Grid>


      <Grid>
        <Modal
          open={modalOpen}

        >
          <Box sx={style}>
            <PageTitle title={"Add Building Assignee"} />



            <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingTypes'
                  value={inputs?.buildingTypes}
                  label="Select Building Type"
                  onChange={onChange}
                >
                  {
                    buildingTypeList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingName'
                  value={inputs?.buildingName}
                  label="Select Building Name"
                  onChange={onChange}
                >
                  {
                    buildingNameList?.filter(name => name?.buildingTypes?._id === inputs?.buildingTypes)?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Assignee Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='type'
                  value={inputs?.buildingFloor?._id}
                  label="Select Assignee Type"
                  onChange={onChange}
                >
                  {/* {
                    buildingFloorList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  } */}
                  <MenuItem value={"Landlord"}>Landlord</MenuItem>
                  <MenuItem value={"Contractor"}>Contractor</MenuItem>
                  <MenuItem value={"Facility Team"}>Facility Team</MenuItem>
                  <MenuItem value={"Workplace Team"}>Workplace Team</MenuItem>
                  <MenuItem value={"Security Team"}>Security Team</MenuItem>
                  <MenuItem value={"Projects Team"}>Projects Team</MenuItem>
                  <MenuItem value={"Leasing Team"}>Leasing Team</MenuItem>
                  <MenuItem value={"Other-Property"}>Other-Property</MenuItem>

                </Select>
              </FormControl>

              {/* <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Space</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingSpaceType'
                  value={inputs?.buildingSpaceType?._id}
                  label="Age"
                  onChange={onChange}
                >
                  {
                    buildingSpaceList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                  
                </Select>
              </FormControl> */}

              <TextField label="Enter Assignee Email" type="email" value={inputs?.title} name='name' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={onChange} />
            </Grid>

            <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
              <Button variant="contained" onClick={onSubmit} style={{ float: "right" }} sx={{ height: 40 }}>
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Submit
              </Button>

              <Button onClick={() => {
                setModalOpen(false)
                setEditMode(false)
              }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>


      <Grid>
        <Modal
          open={editModal}

        >
          <Box sx={style}>
            <PageTitle title={"Update Building Assignee"} />


            <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingTypes'
                  value={buildingInputs?.buildingTypes}
                  label="Select Building Type"
                  onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingTypes: e.target.value })}

                >
                  {
                    buildingTypeList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingName'
                  value={buildingInputs?.buildingName}
                  label="Select Building Name"
                  onChange={(e) => setBuildingInputs({ ...buildingInputs, buildingName: e.target.value })}

                >
                  {
                    buildingNameList?.filter(name => name?.buildingTypes?._id === buildingInputs?.buildingTypes)?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                  {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              {/* <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Floor</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='buildingName'
                  value={buildingInputs?.buildingFloor?._id}
                  label="Age"
                  onChange={onChange}
                >
                  {
                    buildingFloorList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  }
                
                </Select>
              </FormControl> */}

              <FormControl fullWidth style={{ marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-label">Select Building Space</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='type'
                  value={buildingInputs?.type}
                  label="Select Building Space"
                  onChange={(e) => setBuildingInputs({ ...buildingInputs, type: e.target.value })}

                >
                  {/* {
                    buildingFloorList?.map((item, index) => {
                      return <MenuItem key={index} value={item?._id}>{item?.title}</MenuItem>
                    })
                  } */}
                  <MenuItem value={"Landlord"}>Landlord</MenuItem>
                  <MenuItem value={"Contractor"}>Contractor</MenuItem>
                  <MenuItem value={"Facility Team"}>Facility Team</MenuItem>
                  <MenuItem value={"Workplace Team"}>Workplace Team</MenuItem>
                  <MenuItem value={"Security Team"}>Security Team</MenuItem>
                  <MenuItem value={"Projects Team"}>Projects Team</MenuItem>
                  <MenuItem value={"Leasing Team"}>Leasing Team</MenuItem>
                  <MenuItem value={"Other-Property"}>Other-Property</MenuItem>
                </Select>
              </FormControl>

              <TextField label="Enter Building Name" value={buildingInputs?.name} name='name' id='title' variant='outlined' style={{ width: "100%", marginLeft: "10px" }} onChange={editChange} />
            </Grid>



            <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
              <Button variant="contained" onClick={onSubmit} style={{ float: "right" }} sx={{ height: 40 }}>
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Update
              </Button>

              <Button onClick={() => {
                setEditModal(false)
                setEditMode(false)
              }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                Cancel
              </Button>
            </Grid>

            <Grid></Grid>
          </Box>
        </Modal>
      </Grid>



    </Grid>
  )
}

export default AddBuildingAssignee