import React, { useEffect, useState } from 'react'
import "./AddTask.css"
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import LocalDateSelector from '../../../common/LocalDateSelector';
import { getAPI } from '../../../network';
import { postV2API } from '../../../network/index.v2';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Loader from '../../../common/Loader';


const AddTask = () => {
    const [age, setAge] = React.useState('');
    const [siteList, setSiteList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [workTypeList, setWorkTypeList] = useState([])
    const [locationList, setLocationList] = useState([])
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const week = [
        { _id: 1, name: "Sunday" },
        { _id: 2, name: "Monday" },
        { _id: 3, name: "Tuesday" },
        { _id: 4, name: "Wednesday" },
        { _id: 5, name: "Thursday" },
        { _id: 6, name: "Friday" },
        { _id: 7, name: "Saturday" }
    ];
    const months = [
        { _id: 1, name: "January" },
        { _id: 2, name: "February" },
        { _id: 3, name: "March" },
        { _id: 4, name: "April" },
        { _id: 5, name: "May" },
        { _id: 6, name: "June" },
        { _id: 7, name: "July" },
        { _id: 8, name: "August" },
        { _id: 9, name: "September" },
        { _id: 10, name: "October" },
        { _id: 11, name: "November" },
        { _id: 12, name: "December" }
    ];

    const [loader, setLoader] = useState(false)
    const [inputs, setInputs] = useState({
        startDate: "",
        endDate: "",
        timeDue: "",
        shiftRecurrence: "",
        recurrenceDay: [1, 2, 3],
        title: "",
        description: "",
        locationId: "",
        companyId: companyId,
        siteId: "",
        workType: "",
        vendorId: null
    })
    // const [siteList, setSiteList] = useState([])
    // console.log(inputs);


    const getSites = async () => {
        setLoader(true)
        if (companyId) {
            var url = `/sites?companyId=${companyId}`
        } else {
            var url = `/sites`
        }
        let data = await getAPI(url);
        if (data) {
            setSiteList(data)
        }
        setLoader(false)
    }

    const getVendors = async () => {
        setLoader(true)
        if (companyId) {
            var url = `/vendors?company=${companyId}`
        } else {
            var url = `/vendors`
        }
        let data = await getAPI(url);
        if (data) {
            setVendorList(data)
        }
        setLoader(false)
    }



    const handleChange = (e) => {
        e.preventDefault()

       

        setInputs({ ...inputs, [e.target.name]: e.target.value })
    };
    const handleRecurrenceChange = (id) => {
        setInputs((prevState) => ({
          ...prevState,
          recurrenceDay: prevState.recurrenceDay.includes(id)
            ? prevState.recurrenceDay.filter((item) => item !== id) // Remove if already selected
            : [...prevState.recurrenceDay, id], // Add if not selected
        }));
      };
      

    const createMaintenance = async (e) => {
        e.preventDefault()

        try {
            const response = await postV2API("/maintenance/management", inputs)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getSites()
        getVendors()
    }, [])
    return (
        <div className='task-container'>
            <Loader loader={loader}/>
            <div>
                <h1>Add Maintenance Task</h1>
            </div>
            <div className='form-style'>
                <TextField label="Title" variant='outlined' style={{ width: "100%" }} onChange={handleChange} name='title' />
                <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", gap: "10px" }}>
                    <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label" style={{ width: "100%" }}>Work Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={inputs?.workType}
                            label="Work Type"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name='workType'
                        >
                            <MenuItem value={"Preventive Maintenance"}>Preventive Maintenance</MenuItem>
                            <MenuItem value={"Reactive Maintenance"}>Reactive Maintenance</MenuItem>
                            <MenuItem value={"Project"}>Project</MenuItem>
                            <MenuItem value={"Quoted Job"}>Quoted Job</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                    </FormControl >
                    <FormControl fullWidth style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Vendor </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={inputs?.vendorId}
                            label="Vendor"
                            onChange={handleChange}
                            name='vendorId'
                        >
                            {
                                vendorList?.map((vendor) => {
                                    return <MenuItem key={vendor?._id} value={vendor?._id}>{vendor?.name}</MenuItem>
                                })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Sites</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={inputs?.siteId}
                            label="Sites"
                            onChange={handleChange}
                            name='siteId'
                        >
                            {
                                siteList?.map((site) => {
                                    return <MenuItem key={site?._id} value={site?._id}>{site?.name}</MenuItem>
                                })
                            }
                            {/* <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Shift Recurrence</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={inputs?.shiftRecurrence}
                            label="Shift Recurrence"
                            onChange={handleChange}
                            name="shiftRecurrence"
                        >
                            <MenuItem value={"Weekly"}>Weekly</MenuItem>
                            <MenuItem value={"Monthly"}>Monthly</MenuItem>
                            <MenuItem value={"Yearly"}>Yearly</MenuItem>
                        </Select>
                    </FormControl>

                    <FormGroup className='flex'>
                        {inputs.shiftRecurrence === "Weekly"
                            ? week.map((day) => (
                                
                                <FormControlLabel
                                    key={day._id}
                                    control={
                                        <Checkbox
                                            checked={inputs.recurrenceDay.includes(day._id)}
                                          onChange={() => handleRecurrenceChange(day._id)}
                                        />
                                    }
                                    label={day.name}
                                />
                            ))
                            : inputs.shiftRecurrence === "Yearly"
                                ? months.map((month) => (
                                    <FormControlLabel
                                        key={month._id}
                                        control={
                                            <Checkbox
                                                checked={inputs.recurrenceDay.includes(month._id)}
                                              onChange={() => handleRecurrenceChange(month._id)}
                                            />
                                        }
                                        label={month.name}
                                    />
                                ))
                                : null}
                    </FormGroup>


                    <TextField label="Location Id" name='locationId' variant='outlined' style={{ width: "100%" }} value={inputs?.locationId} onChange={handleChange} />
                    <FormControl style={{ width: "100%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="Time Due"
                                value={inputs.timeDue}
                                error={inputs.timeDue}
                                style={{ width: "100%" }}
                                onChange={(data) => {
                                    setInputs(prevState => ({
                                        ...prevState,
                                        timeDue: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </FormControl>
                    {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location Id</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Location ID"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl> */}
                </Box>
                <TextField label="WO Number" variant='outlined' style={{ width: "100%" }} onChange={handleChange} name='woNumber' />
                <TextField label="Service Category Number 1" variant='outlined' style={{ width: "100%" }} onChange={handleChange} />
                <TextField label="Service Category Number 2" variant='outlined' style={{ width: "100%" }} onChange={handleChange} />
                <TextField label="Service Category Number 3" variant='outlined' style={{ width: "100%" }} onChange={handleChange} />

                <TextField label="Description" value={inputs?.description} variant='outlined' style={{ width: "100%" }} onChange={handleChange} name='description' />
                <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px" }}>
                    <FormControl style={{ width: "100%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="modal-component"
                                label="Start Date"
                                style={{ width: "100%" }}
                                inputFormat="dd/MM/yyyy"
                                value={inputs.startDate}
                                // error={person.dueDateError}
                                onChange={(data) => {
                                    setInputs(prevState => ({
                                        ...prevState,
                                        startDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                // error={startDateError}
                                fullWidth
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl style={{ width: "100%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                className="modal-component"
                                label="End Date"
                                style={{ width: "100%" }}
                                inputFormat="dd/MM/yyyy"
                                value={inputs.endDate}
                                // error={person.dueDateError}
                                onChange={(data) => {
                                    setInputs(prevState => ({
                                        ...prevState,
                                        endDate: data,
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                // error={startDateError}
                                fullWidth
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                {/* <Grid className='date-selectors'>
                    <LocalDateSelector
                        title="Start Date"
                    // onChange={handleChange}
                    // value={inputs?.startDate}
                    // name="startDate"
                    />
                    <LocalDateSelector
                        title="End Date"
                    // onChange={handleChange}
                    // value={inputs?.endDate}
                    // name="endDate"
                    />
                </Grid> */}
                <Button type='button' variant='outlined' onClick={createMaintenance}>Add Task</Button>
            </div>
        </div>
    )
}

export default AddTask