import { toast } from "react-toastify";
import { axiosV2Instance } from "../../utils/axiosSetUp.v2";
import { apiErrors } from "../utils";

const responseCheck = (res) => {
   
    if(res.status === 200 || res.status === 201 || res.status === 204){
        return true;
    }
    else{
        return false;
    }
}

const toastObj = {position: toast.POSITION.TOP_RIGHT};


const userToken = localStorage.getItem("token");
console.log("TOKEN---",userToken)

export  const getV2API = async(url) => {
    // console.log(url,"url----")
    try {
        
        const response = await axiosV2Instance.get(url);
        // console.log('API----',axiosV2Instance.get(url))
        if(responseCheck(response)){
            return response.data.data;
            
        }
        if(response.status === 401){
            toast.error('Unauthorized User!',toastObj);
            return false;
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {
        // console.log(err)
        if(err.response.status === 500 || err.response.status === 401){
            toast.error(err.response.data.message, toastObj)
         }
         else{
             let errs = err.response.data.errors;
             apiErrors(errs)
         }
         
         return false;
    }
}


export  const postV2API = async(url,data) => {
    try {
        const response = await axiosV2Instance.post(url, data);
        if(responseCheck(response)){
            toast.success(response.data.message,toastObj);
            return response.data;   
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {

        // console.log('ERRROR-----------', err)
        
        if(err.response.data.code === 500){
           toast.error(err.response.data.message, toastObj)
        }
        else if(err.response.data.code === 404){
            toast.error(err.response.data.message);
        }
        else if(err.response.data.code === 400){
            toast.error(err.response.data.message);
        }
        else{
            let errs = err.response.data.errors;
            apiErrors(errs)
        }
        
        return false;
    }
}


export  const patchV2API = async(url,data) => {
    try {
        const response = await axiosV2Instance.patch(url, data);
        
        if(responseCheck(response)){
            toast.success(response.data.message,toastObj);
            return response.data;
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {
        if(err.response.status === 500){
            toast.error(err.response.data.message, toastObj)
        }
        else{
            let errs = err.response.data.errors;
            apiErrors(errs)
        }
        return false;
    }
}
export  const putV2API = async(url,data) => {
    try {
        const response = await axiosV2Instance.put(url, data);
        if(responseCheck(response)){
            toast.success(response.data.message,toastObj);
            return response.data;
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {
        if(err.response.status === 500){
            toast.error(err.response.data.message, toastObj)
        }
        else{
            let errs = err.response.data.errors;
            apiErrors(errs)
        }
        return false;
    }
}

export  const  deleteV2API = async(url) => {
    try {
        const response = await axiosV2Instance.delete(url);
        if(responseCheck(response)){
            toast.success(response.data.message,toastObj);
            return response.data;
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {
        if(err.response.status === 500){
            toast.error(err.response.data.message, toastObj)
        }
        else{
            let errs = err.response.data.errors;
            apiErrors(errs)
        }
        return false;
    }
}

export  const  getV2ExportAPI = async(url,fileName) => {
    try {
        // console.log('HREF',url)
        const response = await axiosV2Instance({
         url : url,
         method : 'get',
         responseType : 'blob',
         headers: !!userToken ? { Authorization: `Bearer ${userToken}` } : null,
        });
        // console.log('BLOB', response)
        const url2 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url2;
        // const contentDisposition = response.headers['content-disposition'];

        const currentDate = new Date();
        link.setAttribute('download', `${fileName?fileName:"Download"} ${currentDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        // window.open(url, '_blank');
        if(responseCheck(response)){
            return response.data.data;
            
        }
        if(response.status === 401){
            toast.error('Unauthorized User!',toastObj);
            return false;
        }
        else{
            toast.error(response.data.message,toastObj);
            return false;
        }
    } catch (err) {
        // console.log('ERROR',err)
        toast.error(err.message, toastObj)
        // if(err.response.status === 500 || err.response.status === 401){
        //     toast.error(err.response.data.message, toastObj)
        //  }
        //  else{
        //      let errs = err.response.data.errors;
        //      apiErrors(errs)
        //  }
         
         return false;
    }
}