import React, { useEffect, useState } from 'react'
import PageTitle from '../../../common/PageTitle'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from '@material-ui/core'
import { checkAuthority, tableData, tableHeader } from '../../../utils'
import { getV2API, postV2API, putV2API } from '../../../network/index.v2'
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../../../common/Loader'
import { useParams } from 'react-router-dom'

const AnswerWorkPermit = () => {
    let companyId = localStorage.getItem('companyIdLS')
    if (companyId === null) {
        companyId = undefined
    }
    const [workPermitList, setWorkPermitList] = useState([])
    const [loader, setLoader] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const { _id } = useParams()
    const [inputs, setInputs] = useState({
        workPermitId: _id,
        questionId: "",
        comment: ""
    })
    const [optionInput, setOptionInput] = useState("")
    const [responseInputs, setResponseInputs] = useState({
        workPermitId: _id,
        questionId: "",
        comment: ""
    })

    const addOption = () => {
        if (!editMode) {
            if (optionInput.trim() && !inputs?.options?.includes(optionInput.trim())) {
                setInputs((prev) => ({
                    ...prev,
                    options: [...prev.options, optionInput.trim()]
                }))
                setOptionInput("")
            }
        }

        if (optionInput.trim() && !responseInputs?.options?.includes(optionInput.trim())) {
            setResponseInputs((prev) => ({
                ...prev,
                options: [...prev.options, optionInput.trim()]
            }))
            setOptionInput("")
        }
    }

    // const handleOptionDelete = (deleteOption) => {
    //     if (!editMode) {
    //         setInputs((prev) => ({
    //             ...prev,
    //             options: prev.options.filter((option) => option !== deleteOption)
    //         }))
    //     }

    //     setResponseInputs((prev) => ({
    //         ...prev,
    //         options: prev.options.filter((option) => option !== deleteOption)
    //     }))
    // }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
            addOption();
        }
    };



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
    };

    console.log(responseInputs);



    // const editPermit = async (e, _id) => {
    //     e.preventDefault()

    //     setEditModal(true)
    //     setEditMode(true)

    //     // console.log(w);


    //     let data = workPermitList?.filter(item => item?._id === _id)[0]

    //     setResponseInputs(data)



    // }

    const postWorkPermit = async () => {
        setLoader(true)
        const response = await postV2API("/maintenance/workpermitResponse", inputs)
        if (response) {
            getPermitList()
            setModalOpen(false)
            setEditMode(false)
        }
        setLoader(false)
    }

    const getPermitList = async () => {
        // if (companyId) {
        //     var url = `/sites?companyId=${companyId}`
        // } else {
        //     var url = `/sites`
        // }
        setLoader(true)
        let response = await getV2API("/maintenance/workpermitResponse");
        if (response) {
            setWorkPermitList(response)
        }
        setLoader(false)
    }
    // console.log(workPermitList);

    const editWorkPermit = async () => {
        setLoader(true)
        const response = await putV2API(`/maintenance/workpermitQuestion/${responseInputs?._id}`, responseInputs)
        if (response) {
            getPermitList()
            setEditModal(false)
            setEditMode(false)
        }
        setLoader(false)

    }



    // const deleteWorkPermit = async (_id) => {
    //     // console.log(_id);
    //     setLoader(true)
    //     const response = await deleteV2API(`/maintenance/workpermitQuestion/${_id}`)
    //     if (response) {
    //         getPermitList()
    //     }
    //     setLoader(false)
    // }

    useEffect(() => {
        getPermitList()
    }, [])
    return (
        <div>
            <Grid sx={{ display: "flex", justifyContent: "space-between", width: "100%", height: "45px", marginBottom: "10px" }}>
                <PageTitle title="Work Permits Questions" />
                <Button onClick={() => {
                    setModalOpen(true)
                    setEditMode(false)
                }} variant='contained'>Add Question</Button>
            </Grid>
            {
                loader ? <Loader /> : <Grid>
                    <TableContainer component={Paper} sx={{ mx: "0.8rem" }} >
                        <Table sx={{ minWidth: 'auto' }} aria-label="custom pagination table" className="responsive-table">
                            <TableHead >
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>Category</TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>Options</TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>Name</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Questions</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Response</TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>Comment</TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    workPermitList?.map((item, index) => {


                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.workPermitId?.category}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.questionId?.options?.map((op, i) => <li key={i}>{op}</li>)}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.workPermitId?.name}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.questionId?.question}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.response}
                                                </TableCell>
                                                <TableCell align="left" sx={tableData}>
                                                    {item?.comment}
                                                </TableCell>
                                                {/* <TableCell align="left" sx={tableData}>
                                                    {item?.response}
                                                </TableCell> */}


                                                {/* <TableCell align="center" sx={tableData}>
                                                    {
                                                        checkAuthority('EDIT_ALERT') &&
                                                        <Button
                                                            className="btn-div" color="primary"
                                                            variant="outlined" sx={{ mx: 2 }}
                                                            onClick={(e) => editPermit(e, item?._id)}
                                                        >
                                                            <EditIcon className="" />
                                                        </Button>
                                                    }
                                                    {
                                                        checkAuthority('DELETE_ALERT') &&
                                                        <Button
                                                            className="btn-div" color="error"
                                                            variant="outlined"
                                                            onClick={() => deleteWorkPermit(item?._id)}
                                                        >
                                                            <DeleteIcon className="" />
                                                        </Button>
                                                    }
                                                </TableCell> */}

                                            </TableRow>
                                        )
                                    }
                                    )
                                }
                            </TableBody>
                            {
                                checkAuthority('ADD_SITE_INSPECTION') &&
                                <TableFooter>
                                    <TableRow>

                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                        <TableCell align="left" sx={tableData}>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            }

                        </Table>
                    </TableContainer>
                </Grid>
            }

            <div>
                <Modal
                    open={modalOpen}

                >
                    <Box sx={style}>
                        <PageTitle title={"Add Question "} />

                        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>

                            <FormControl fullWidth style={{ marginLeft: "10px" }}>
                                <InputLabel id="demo-simple-select-label">Question Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputs?.questionId}
                                    label="Question Id"
                                    name='questionId'
                                    onChange={(e) => setInputs({ ...inputs, questionId: e.target.value })}
                                >
                                    {
                                        workPermitList?.map((question) => {
                                            return (
                                                <MenuItem key={question?._id} value={question?.questionId?._id}>{question?.questionId?.question}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </FormControl>
                            <TextField
                                label="Enter Comment"
                                value={inputs?.comment}
                                name='comment'
                                id='question'
                                variant='outlined'
                                style={{ width: "100%", marginLeft: "10px" }}
                                onChange={(e) => setInputs({ ...inputs, comment: e.target.value })}
                            />
                            <Grid style={{ display: "flex", gap: "10px" }}>
                                <TextField
                                    label="Enter  Answer"
                                    value={inputs?.response}
                                    name='response'
                                    id='response'
                                    variant='outlined'
                                    style={{ width: "100%", marginLeft: "10px" }}
                                    onChange={(e) => setInputs({ ...inputs, response: e.target.value })}
                                // onKeyPress={handleKeyPress}
                                />
                                {/* <Button variant='text' onClick={addOption}>Add</Button> */}
                            </Grid>
                            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginLeft: '10px', marginTop: '10px' }}>
                                {inputs.options.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option}
                                        onDelete={() => handleOptionDelete(option)}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ))}
                            </Box> */}
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={postWorkPermit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Submit
                            </Button>

                            <Button onClick={() => {
                                setModalOpen(false)
                                setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>


            <div>
                <Modal
                    open={editModal}

                >
                    <Box sx={style}>
                        <PageTitle title={"Update Building Floor"} />

                        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <TextField
                                label="Enter  Question"
                                value={responseInputs?.question}
                                name='question'
                                id='question'
                                variant='outlined'
                                style={{ width: "100%", marginLeft: "10px" }}
                                onChange={(e) => setResponseInputs({ ...responseInputs, question: e.target.value })}
                            />
                            <Grid style={{ display: "flex", gap: "10px" }}>
                                <TextField
                                    label="Enter  Answer"
                                    value={optionInput}
                                    name='description'
                                    id='description' variant='outlined'
                                    style={{ width: "100%", marginLeft: "10px" }}
                                    onChange={(e) => setOptionInput(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                                <Button variant='text' onClick={addOption}>Add</Button>
                            </Grid>
                            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginLeft: '10px', marginTop: '10px' }}>
                                {responseInputs.options.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={option}
                                        onDelete={() => handleOptionDelete(option)}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ))}
                            </Box> */}
                        </Grid>

                        <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                            <Button variant="contained" onClick={editWorkPermit} style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Update
                            </Button>

                            <Button onClick={() => {
                                setEditModal(false)
                                setEditMode(false)
                            }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>
                                {/* <AddCircleIcon /> &nbsp; &nbsp; */}
                                Cancel
                            </Button>
                        </Grid>

                        <div></div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default AnswerWorkPermit