import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { deleteV2API, getV2API, getV2ExportAPI, postV2API, putV2API } from '../../../network/index.v2';
import { tableData } from '../../../utils';
import EmptyTable from '../../../common/EmptyTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageTitle from '../../../common/PageTitle';




export const tableHeader = {
    fontSize: '0.9rem !important',
    fontWeight: 500,
    color: '#45445F',
    px: 3,
    paddingLeft: '5px',
    paddingRight: '5px',
    padding: '12px'
}

const ContractorModal = () => {
    const [ContractorList, setContractorList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [vendorNumber, setVendorNumber] = useState("")
    const [supplierName, setSupplierName] = useState("")
    const [_id, setId] = useState("")
    const [ABN, setABN] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [address, setAddress] = useState("")
    const [state, setState] = useState("")
    const [suburb, setSuburb] = useState("")
    const [email, setEmail] = useState("")
    const [contactName, setContactName] = useState("")
    const [postcode, setPostCode] = useState("")

    const [inputs, setInputs] = useState({
        vendorNumber: "",
        supplierName: "",
        ABN: '',
        email: "",
        phoneNumber: "",
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        contactName: ""
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        // height: 300,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: "2px"
    };

    // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


    const [page, setPage] = useState(0)
    const [perPages, setPerPages] = useState([10, 25, 50]);
    const [perPage, setPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        console.log(event.target.value)
        setPerPage(event.target.value)
        setPage(0);
    };

    const editContactor = (e, _id) => {
        e.preventDefault()

        const filteredId = ContractorList?.filter(item => item?._id === _id)
        setId(filteredId[0]?._id)
        setVendorNumber(filteredId[0]?.vendorNumber)
        setSupplierName(filteredId[0]?.supplierName)
        setABN(filteredId[0]?.ABN)
        setEmail(filteredId[0]?.email)
        setAddress(filteredId[0]?.address)
        setPhoneNumber(filteredId[0]?.phoneNumber)
        setState(filteredId[0]?.state)
        setPostCode(filteredId[0]?.postcode)
        setSuburb(filteredId[0]?.suburb)
        setContactName(filteredId[0]?.contactName)
        setEditModal(true)

    }


    // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

    const handleChange = (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

    }

    const createContractor = async (e) => {
        e.preventDefault()

        const fD = new FormData()
        fD.append("vendorNumber", inputs?.vendorNumber)
        fD.append("supplierName", inputs?.supplierName)
        fD.append("ABN", inputs?.ABN)
        fD.append("email", inputs?.email)
        fD.append("phoneNumber", inputs?.phoneNumber)
        fD.append("address", inputs?.address)
        fD.append("postcode", inputs?.postcode)
        fD.append("state", inputs?.state)
        fD.append("suburb", inputs?.suburb)
        fD.append("contactName", inputs?.contactName)

        const response = await postV2API("/maintenance/contractor", fD)
        setModalOpen(false)
        clearInputs()
        getContractorList()
    }

    const updateContractor = async (e) => {
        e.preventDefault()

        const fD = new FormData()
        fD.append("vendorNumber", vendorNumber)
        fD.append("supplierName", supplierName)
        fD.append("ABN", ABN)
        fD.append("email", email)
        fD.append("phoneNumber", phoneNumber)
        fD.append("address", address)
        fD.append("postcode", postcode)
        fD.append("state", state)
        fD.append("suburb", suburb)
        fD.append("contactName", contactName)

        const response = await putV2API(`/maintenance/contractor/${_id}`, fD)
        setEditModal(false)
        // clearInputs()
        getContractorList()
    }

    const getContractorList = async () => {
        try {
            const response = await getV2API("/maintenance/contractor")
            if (response) {
                setContractorList(response)
            }
        } catch (error) {
            console.log(error, "error from api");
        }
    }

    const deleteContract = async (id) => {
        try {
          const response = await deleteV2API(`/maintenance/contractor/${id}`)
          if (response) {
            getContractorList()
          }
        } catch (error) {
          console.log(error, "error in api");
        }
      }

    const clearInputs = () => {
        setInputs((prev) => ({
            ...prev,
            vendorNumber: "",
            supplierName: "",
            ABN: '',
            email: "",
            phoneNumber: "",
            address: "",
            suburb: "",
            state: "",
            postcode: "",
            contactName: ""
        }))
    }

    


    useEffect(() => {
        getContractorList()
    }, [])
    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

                <div>
                    <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
                        <AddCircleIcon /> &nbsp; &nbsp;
                        Add Contractor
                    </Button>
                </div>
                <Box display="flex" sx={{ my: "1rem" }}>

                    <TableContainer component={Paper}>

                        <Table
                            sx={{ minWidth: "auto" }}
                            aria-label="custom pagination table"
                            className="responsive-table"
                        >
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Number
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contractor Name
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        ABN
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Email
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Phone Number
                                    </TableCell>
                                    <TableCell align="left" component="th" sx={tableHeader}>
                                        Contact Name
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Address
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Suburb
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        State
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Postcode
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Status
                                    </TableCell>

                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell>
                                    {/* <TableCell align="center" component="th" sx={tableHeader}>
                                        Task Status
                                    </TableCell>
                                    <TableCell align="center" component="th" sx={tableHeader}>
                                        Action
                                    </TableCell> */}

                                </TableRow>
                            </TableHead>
                            <TableBody>


                                {
                                    [...ContractorList]?.reverse().slice(page * perPage, page * perPage + perPage).map((task, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.phoneNumber}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.contactName}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.ABN}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.email}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.phoneNumber}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.contactName}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.address}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.suburb}
                                            </TableCell>
                                            <TableCell align="left" sx={tableData}>
                                                {task?.state}
                                            </TableCell>
                                            {/* <TableCell align="left" sx={tableData}>

                                            {task?.isMatch === true ?
                                                <Button style={{ color: 'white', backgroundColor: 'green' }}> Matched</Button> :
                                                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={() => handleMapData(task)}>Mismatch</Button>
                                            }
                                            <i>
                                                {task?.isMatch ? null : `${task?.distance} km away`}
                                            </i>
                                        </TableCell> */}
                                            <TableCell align="left" sx={tableData}>
                                                {task?.postcode}
                                            </TableCell>

                                            <TableCell align="left" sx={tableData}>
                                                Non Critical
                                            </TableCell>
                                            <TableCell align="left" sx={{ display: "flex" }} >
                                                <Button size="small" className="btn-div" variant="outlined" color="info"
                                                    onClick={(e) => editContactor(e, task?._id)}
                                                >
                                                    <EditIcon className="" />
                                                </Button>
                                                <Button size="small" className="btn-div" variant="outlined" color="error"
                                                onClick={() => deleteContract(task?._id)}
                                                >
                                                    <DeleteIcon className="" />
                                                </Button>
                                            </TableCell>
                                            {/* <TableCell align="left" sx={tableData}>
                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setOpenCheckout(true)}>Checked Out</Button>

                                            <Button variant="outlined" color="error" onClick={() => setOpenCheckout(true)}>Forced Check Out</Button>

                                            <Button variant="outlined" style={{ color: 'white', backgroundColor: 'orange' }} onClick={() => setOpenCheckout(true)}>Check Out</Button>
                                        </TableCell> */}

                                        </TableRow>

                                    ))}

                                {
                                    ContractorList.length === 0 &&
                                    <EmptyTable colSpan={6} />
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow >

                                    <TablePagination
                                        align="right"
                                        rowsPerPageOptions={perPages}
                                        colSpan={7}
                                        count={ContractorList.length}
                                        rowsPerPage={perPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </div>

            <div>
                <Modal
                    open={modalOpen}
                >
                    <Box sx={style}>
                        {/* <h6>Close</h6> */}
                        <div className='box-title'>
                            <h2>Add Property</h2>
                        </div>
                        <form onSubmit={createContractor}>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                                <TextField variant='outlined' label="Vendor Number" className='box-input' name='vendorNumber' onChange={handleChange} />
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.propertytypeId}
                                        name='propertytypeId'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            propertyTypeList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                      
                                    </Select>
                                </FormControl> */}
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs?.propertycategoryId}
                                        name='propertycategoryId'
                                        label="Property Type"
                                        onChange={handleChange}
                                    >
                                        {
                                            PropertyCategoryList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                     
                                    </Select>
                                </FormControl> */}
                                <TextField variant='outlined' label="supplierName" className='box-input' name='supplierName' onChange={handleChange} />
                                <TextField variant='outlined' label="ABN" className='box-input' name='ABN' onChange={handleChange} />
                                <TextField variant='outlined' label="email" className='box-input' name='email' onChange={handleChange} />
                                <TextField variant='outlined' label="phoneNumber" className='box-input' name='phoneNumber' onChange={handleChange} />
                                <TextField variant='outlined' label="address" className='box-input' name='address' onChange={handleChange} />
                                <TextField variant='outlined' label="suburb" className='box-input' name='suburb' onChange={handleChange} />
                                <TextField variant='outlined' label="state" className='box-input' name='state' onChange={handleChange} />
                                <TextField variant='outlined' label="postcode" className='box-input' name='postcode' onChange={handleChange} />
                                <TextField variant='outlined' label="contactName" className='box-input' name='contactName' onChange={handleChange} />
                            </div>
                            <div className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>


            <div>
                <Modal
                    open={editModal}

                >
                    <Box sx={style}>
                        <PageTitle title={"Update Contractor"} />

                        <form onSubmit={updateContractor}>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                                <TextField variant='outlined' label="Vendor Number" value={vendorNumber} className='box-input' name='vendorNumber' onChange={(e) => setVendorNumber(e.target.value)} />
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={propertyTypeId}
                                        name='propertytypeId'
                                        label="Property Type"
                                        onChange={(e) => setPropertyTypeId(e.target.value)}
                                    >
                                        {
                                            propertyTypeList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                      
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={propertyCategoryId}
                                        name='propertycategoryId'
                                        label="Property Type"
                                        onChange={(e) => setPropertyCategoryId(e.target.value)}
                                    >
                                        {
                                            PropertyCategoryList?.map((property) => {
                                                return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                                            })
                                        }
                                        
                                    </Select>
                                </FormControl> */}
                                <TextField variant='outlined' label="Supplier Name" className='box-input' value={supplierName} name='supplierName' onChange={(e) => setSupplierName(e.target.value)} />
                                <TextField variant='outlined' label="ABN" className='box-input' value={ABN} name='ABN' onChange={(e) => setABN(e.target.value)} />
                                <TextField variant='outlined' label="Address" className='box-input' value={address} name='address' onChange={(e) => setAddress(e.target.value)} />
                                <TextField variant='outlined' label="postcode" className='box-input' value={postcode} name='country' onChange={(e) => setPostCode(e.target.value)} />
                                <TextField variant='outlined' label="State" className='box-input' value={state} name='state' onChange={(e) => setState(e.target.value)} />
                                <TextField variant='outlined' label="Suburb" className='box-input' value={suburb} name='suburb' onChange={(e) => setSuburb(e.target.value)} />
                                <TextField variant='outlined' label="email" className='box-input' value={email} name='email' onChange={(e) => setEmail(e.target.value)} />
                                <TextField variant='outlined' label="contact name" className='box-input' value={contactName} name='contactName' onChange={(e) => setContactName(e.target.value)} />
                                {/* <TextField variant='outlined' label="latLong" className='box-input' value={latLong} name='latLOng' onChange={(e) => setLatLong(e.target.value)} /> */}
                            </div>
                            <div className='box-button-group'>
                                <Button variant='contained' type='submit'>Submit</Button>
                                <Button variant='outlined' onClick={() => setEditModal(false)} >Cancel</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default ContractorModal