import React, { useEffect, useState } from 'react'
import PageTitle from '../../../../common/PageTitle'
import { Box, Button, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { tableHeader } from '../Addproperty';
import { tableData } from '../../../../utils';
import { deleteV2API, getV2API, postV2API, putV2API } from '../../../../network/index.v2';
import EmptyTable from '../../../../common/EmptyTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PropertyCategory = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const [PropertyCategoryList, setPropertyCategoryList] = useState([])
  const [propertyTypeList, setPropertyTypeList] = useState([])
  const [inputs, setInputs] = useState({ title: "", propertytypeId: "" })
  const [title, setTitle] = useState("")
  const [propertyTypeId, setPropertyTypeId] = useState("")
  const [_id, setId] = useState("")


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    width: 500,
    height: 300,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderRadius: "2px"
  };

  // -------------------------------------------- Table States and Static Data Manipulation Start --------------------------------------


  const [page, setPage] = useState(0)
  const [perPages, setPerPages] = useState([10, 25, 50]);
  const [perPage, setPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value)
    setPerPage(event.target.value)
    setPage(0);
  };


  // -------------------------------------------- Table States and Static Data Manipulation Ends --------------------------------------

  const handleChange = (e) => {
    e.preventDefault()

    setInputs({ ...inputs, [e.target.name]: e.target.value })

  }

  const editPropetyCategory = (e, _id) => {
    e.preventDefault()

    const filteredId = PropertyCategoryList?.filter(item => item?._id === _id)
    setId(filteredId[0]?._id)
    setTitle(filteredId[0]?.title)
    setPropertyTypeId(filteredId[0]?.propertytypeId?._id)
    setEditModal(true)

  }

  // -------------------------------------------- Property Category CRUD starts -----------------------------------------------------------

  const createCategory = async (e) => {
    e.preventDefault()

    const fD = new FormData()
    fD.append("title", inputs?.title)
    fD.append("propertytypeId", inputs?.propertytypeId)

    const response = await postV2API("/maintenance/property/category", fD)
    setModalOpen(false)
    clearInputs()
    getPropertyCategory()
  }

  const updatePropertyCategory = async (e) => {
    e.preventDefault()
    const fD = new FormData()
    fD.append("title", title)
    fD.append("propertytypeId", propertyTypeId)
    try {
      const response = await putV2API(`/maintenance/property/category/${_id}`, fD)
      setEditModal(false)
      setTitle("")
      setPropertyTypeId("")
      setId("")
      getPropertyCategory()

    } catch (error) {
      console.log(error);
    }
  }

  const getPropertyCategory = async () => {
    try {
      const response = await getV2API("/maintenance/property/category")
      if (response) {
        setPropertyCategoryList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }

  const getPropertyTypes = async () => {
    try {
      const response = await getV2API("/maintenance/property/type")
      if (response) {
        setPropertyTypeList(response)
      }
    } catch (error) {
      console.log(error, "error from api");
    }
  }

  const deletePropertyCategory = async (id) => {
    try {
      const response = await deleteV2API(`/maintenance/property/category/${id}`)
      if (response) {
        getPropertyCategory()
      }
    } catch (error) {
      console.log(error, "error in api");
    }
  }


  // -------------------------------------------- Property Category CRUD Ends -------------------------------------------------------------


  useEffect(() => {
    getPropertyCategory()
    getPropertyTypes()
  }, [])

  const clearInputs = () => {
    setInputs((prev) => ({
      ...prev,
      title: "",
      propertytypeId: ""
    }))
  }

  return (
    <div>

      <PageTitle title={"Property Category"} />


      <div>
        <Button onClick={() => setModalOpen(true)} variant="contained" style={{ backgroundColor: "#75859D", float: "right" }} sx={{ height: 52, mx: 2 }}>
          <AddCircleIcon /> &nbsp; &nbsp;
          Add Property Category
        </Button>
      </div>

      <div style={{ marginTop: "50px" }}>

        <TableContainer component={Paper} sx={{ mx: "0.8rem", width: "60%", my: "6rem" }}>
          {/* <div style={{width: 'auto', overflowX: 'scroll'}}> */}

          <Table
            sx={{ minWidth: "auto" }}
            aria-label="custom pagination table"
            className="responsive-table"
          >
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="left" component="th" sx={tableHeader}>
                  Property Category
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Property Type
                </TableCell>
                <TableCell align="left" component="th" sx={tableHeader}>
                  Action
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {
                [...PropertyCategoryList]?.reverse().slice(page * perPage, page * perPage + perPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={tableData}>
                      {item?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      {item?.propertytypeId?.title}
                    </TableCell>
                    <TableCell align="left" sx={tableData}>
                      <Button size="small" className="btn-div" variant="outlined" color="info"
                        onClick={(e) => editPropetyCategory(e, item?._id)}
                      >
                        <EditIcon className="" />
                      </Button>
                      <Button size="small" className="btn-div" variant="outlined" color="error"
                        onClick={() => deletePropertyCategory(item?._id)}
                      >
                        <DeleteIcon className="" />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              {
                !PropertyCategoryList &&
                <EmptyTable colSpan={9} />
              }
            </TableBody>
            <TableFooter>
              <TableRow>

                <TablePagination
                  align="right"
                  rowsPerPageOptions={perPages}
                  colSpan={9}
                  count={1}
                  rowsPerPage={perPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </div> */}
        </TableContainer>

      </div>


      <div>
        <Modal
          open={modalOpen}
        >
          <Box sx={style}>
            {/* <h6>Close</h6> */}
            <div className='' >
              <PageTitle title={"Add Property Category"} />
            </div>
            <form onSubmit={createCategory}>
              <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                <TextField variant='outlined' label="Property Name" className='box-input' name='title' onChange={handleChange} />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs?.propertytypeId}
                    name='propertytypeId'
                    label="Property Type"
                    onChange={handleChange}
                  >
                    {
                      propertyTypeList?.map((property) => {
                        return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                      })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              <div className='box-button-group'>
                <Button variant='contained' type='submit'>Submit</Button>
                <Button variant='outlined' onClick={() => setModalOpen(false)} >Cancel</Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>


      <div>
        <Modal
          open={editModal}

        >
          <Box sx={style}>
            <PageTitle title={"Update Property Category"} />

            <form onSubmit={updatePropertyCategory} style={{width:"100%", display:"flex", flexDirection:'column', gap:"10px"}}>
              <TextField label="Enter Category Name" value={title} name='title' id='title' variant='outlined' style={{ width: "100%" }} onChange={(e) => setTitle(e.target.value)} />
              <FormControl style={{width:"100%"}}>
                <InputLabel id="demo-simple-select-label">Property Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={propertyTypeId}
                  name='propertytypeId'
                  label="Property Type"
                  onChange={(e) => setPropertyTypeId(e.target.value)}
                >
                  {
                    propertyTypeList?.map((property) => {
                      return <MenuItem key={property?._id} value={property?._id}>{property?.title}</MenuItem>
                    })
                  }
                  {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <Grid style={{ marginTop: "15px", width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                <Button variant="contained" type='submit' style={{ float: "right" }} sx={{ height: 40 }}>

                  Update
                </Button>

                <Button onClick={() => {
                  setEditModal(false)
                  // setEditMode(false)
                }} variant="outlined" style={{ float: "right" }} sx={{ height: 40 }}>

                  Cancel
                </Button>
              </Grid>
            </form>



            <div></div>
          </Box>
        </Modal>
      </div>



    </div>
  )
}

export default PropertyCategory